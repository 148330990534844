// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: "good-times", sans-serif;
    --fancy-slider-title-line-height: 1;
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 0.6;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1.2;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        padding-top: 1.5em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 0.5em;
        }

        .btn{
            &, 
            &_link{
              font-family: $Poppins;
            }

            &_link{
                @extend .text-underLine
            }
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &Grid{
            display: grid;
            grid-column-gap: 0;
            grid-auto-rows: 0fr 0fr 0fr;
            &-list{
                &-2to1{
                    grid-template-columns: 2fr 1fr 1fr;
                }
                
                &-avg{
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                &-1to2{
                    grid-template-columns: 1fr 1fr 2fr;
                }
            }

            @include pad1080{
                grid-template-columns: auto !important;
            }
        }

        // 標題用
        &Head{
            @include fontSize(48px);
            @extend .fw-900;
            line-height: 1;
            -webkit-text-stroke: 2px #000;
            @include setMaxRwd{
                @include fontSize(35px);
                br{
                    display: none;
                }
            }
        }

        // 內文用
        &Article{
            line-height: 1.5;
        }

        &Blog{
            // 文字內容
            &-subtitle{
                @include fontSize(15px);
                @extend .fw-900
            }

            &-title{
                @include fontSize(48px);
                margin-top: get-vw-mobile(10px);
                margin-bottom: get-vw-mobile(30px);
                @include setMinRwd{
                    margin-top: get-vw(10px);
                    margin-bottom: get-vw(30px);
                }
                
                @include setWidthRwd(1025px,1700px){
                    @include fontSize(30px);
                }

                @include setMaxRwd{
                    @include fontSize(30px);
                }
            }

            &-article{}

            .icon {
                vertical-align: top;
                margin-top: -1px;
            }

            &-picbox,
            &-txtbox{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-txtbox{
                color: $colorBase;
                padding:1.25em 1em;

                @include setMinRwd{
                    padding: get-vw(100px) get-vw(70px);
                    & + .index-pageBlog-box{
                        border-left: #e8e8e8 1px solid;
                    }
                }

                @include setWidthRwd(1025px,1500px){
                    padding: get-vw(40px) get-vw(30px);
                }

                @include setMaxRwd{
                    text-align: center;
                    order: -1;
                }

                &Main{
                    background-color: $colorMain;
                    color: #fff
                }
            }
            
            @include setStyleMinRwd(760px){
                margin-top: 50px;
                padding-bottom: 0;
            }

            @include setStyleRwd(900px){
                margin-top: 0.25rem;
            }

            @include setMinRwd{
                margin-top: 100px
            }

            @include setMaxRwd{
                padding-left: 0;
                padding-right: 0;
            }
        }

        &About{
            @include setBackSet(bottom,100%);
            background-image: url('../images/index-pageAbout.jpg');

            @include setMaxRwd{
                padding-bottom: get-vw-mobile(100px);
            }

            .index-page{
                &Wrapper,
                &Head,
                &Article{
                    padding: 0.5rem;
                }
            }

            .index-pageWrapper{
                @include setMinRwd{
                   padding-bottom: get-vw(200px);
                }

                @include setWidthRwd(1100px,1600px){
                    
                }
            }

            .index-pageHead{
                @include setMinRwd{
                    width: calc(580 / 1650 * 100%);
                    padding-bottom: get-vw(300px);
                    padding-right: get-vw(65px);
                }
            }

            .index-pageArticle{
                @include setMinRwd{
                    flex: 1;
                    padding: get-vw(115px) 0 get-vw(150px) get-vw(65px);
                    border-left: #222222 1px solid;
                }
            }
        }

        &Pro{
            @include setStyleMinRwd(760px){
                padding-top: 0;
            }

            &-list{
                flex: 1;
                width: 100%;
                padding-top: get-vw-mobile(30px);

                @include setMinRwd{
                    padding-top: get-vw(100px);
                }

                @include setStyleMinRwd(1000px){
                    width: 50%;
                }

                .desbox{
                    color: $colorBase;
                    font-style: italic;
                    @include setStyleMinRwd(760px){
                        padding-top: get-vw(75px);
                        padding-bottom: get-vw(40px);
                    }

                    @include setMaxRwd{
                        text-align: center;
                        padding-bottom: get-vw-mobile(60px);
                    }
                }

                .videobox{
                    @include setStyleMinRwd(760px){
                        margin-top: get-vw(30px)
                    }

                    @include setWidthRwd(1000px,1600px){
                        margin-top: get-vw(20px)
                    }

                    @include setMaxRwd{
                        .ratio{
                            &::before {
                                padding-top: var(--bs-aspect-ratio-mobile);
                            }
                        }
                    }
                }
            }

            &-video{
                .desbox{
                    @include setStyleMinRwd(760px){
                        padding-left: get-vw(20px);
                        padding-right: get-vw(20px);
                    }

                    @include setStyleMinRwd(1700px){
                        padding-left: get-vw(140px);
                    }
                }
            }

            &-swiper{
                &::before{
                    content: '';
                    display: block;
                    @include setSize(100%,get-vw(557px));
                    background-color: #fff3f3;
                    position: absolute;
                    top: 0;
                    z-index: 0;

                    @include setWidthRwd(1000px,1600px){
                        @include setSize(100%,get-vw(675px));
                    }

                    @include setMaxRwd{
                        @include setSize(100%,21rem);
                    }

                    @include mobileSmall{
                        @include setSize(100%,18.45rem);
                    }
                }

                &-box{
                    @extend .wrapper-650;
                    @include setWidthRwd(1000px,1600px){
                        padding-left: get-vw(50px);
                        padding-right: get-vw(50px);
                    }
                }
                
                .desbox{
                    padding-top: get-vw(45px);
                    @include setWidthRwd(1000px,1600px){
                        padding-left: get-vw(40px);
                        padding-right: get-vw(40px);
                    }
                }

                .card{

                    background-color: transparent;

                    @include setMinRwd{
                        &-wrap{
                            display: grid;
                            grid-template-columns: repeat(2, minmax(270px, 1fr));
                            margin-bottom: 0;
                        }

                        margin-bottom: get-vw(65px);
    
                        grid-row: span 2;
                        &:nth-child(even){
                            grid-row: span 1;
                        }
    
                        &:nth-last-of-type(1),
                        &:nth-last-of-type(2){
                            margin-bottom: get-vw(30px);
                        }
                    }

                    @include setMaxRwd{
                        &-wrap{
                            margin-top: 0;
                        }
                    }

                    &-img-top{
                        text-align: center;
                        margin-bottom: 20px !important;
                        img{
                            width: 100% !important;
                        }

                        @include setMaxRwd{
                            margin-bottom: 0.5rem !important;
                        }
                    }

                    &-title{
                        @include fontSize(22px);
                        margin-bottom: 12px !important;
                    }
                }
            }
        }
    }
}

.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}


.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            .fancy-slider-title{
                position: absolute;
                bottom: get-vw-mobile(30px);
            }
            @include setMinRwd{
                .fancy-slider-title{
                    bottom: get-vw(130px);
                }

                .fancy-slider-scale{
                    position: relative;
                }
            }

            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }
        }

        &-main{
            @include mobile{
                img{
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }

    &-scrollDown{
        position: relative;
        height: 75px;
        margin: 2em;
        &-btn {
            position: absolute;
            @include setSize(37px,72px);
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            animation: jump 1.5s infinite;
        }
    
        @include mobile{
            display: none;
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title,
            &-time,
            &-img-top{
                margin-bottom: 25px;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                img{
                    display: inline-block;
                    @include setStyleMinRwd(760px){
                        width: auto;
                    }
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.056;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 
        &-news{
            padding-bottom: 10px;

            @include setStyleMinRwd(760px){
                height: 0;
                padding-bottom: 36%;
            }

            @include setWidthRwd(765px,1000px){
                padding-bottom: 45%;
            }

            .swiper{
                &-slide{
                    @include setStyleMinRwd(760px){
                        // 預設
                        transition: 300ms;
                        transform: scale(0.7) !important;
                        transform-origin: top;
                    }

                    .card{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;

                        @include setStyleMinRwd(900px){
                            margin-left: 0;
                            margin-right: 0;
                        }

                        @include setWidthRwd(765px,1000px){
                            margin-right: 1.5em;
                        }
    
                        &-title{
                            @include fontSize(48px);
                                
                            @include setWidthRwd(1100px,1700px){
                                @include fontSize(38px);
                            }

                            @include setMaxRwd{
                                @include fontSize(30px);
                            }
                        }

                        &-time{
                            @include fontSize(20px);
                        }
                        
                        @include setMinRwd{
                            padding-left: get-vw(75px);
                            padding-right: get-vw(75px);
                        }
                    }
    
                    &-prev{
                        .card{
                            @include setMinRwd{ 
                                padding-left: 0;
                                padding-right: get-vw(160px);
                                margin-left: get-vw(-40px);
                            }
                        }
                    }

                    &-prev,
                    &-next{
                        @include setStyleMinRwd(760px){
                            .card{
                                &-img-top{
                                    img{
                                        width: 143px;
                                        height: 210px;
                                        object-fit: cover;
                                        opacity: 0.45;
                                    }
                                }
                            }
                        }
                    }
                    
                    &-next{
                        .card{
                            @include setMinRwd{ 
                                padding-left: get-vw(140px);
                                padding-right: 0;
                                &-img-top{
                                    order: -1;
                                }
                            }
                        }
                    }
    
                    &-active{
                        .card{
                            &-title{
                                @include fontSize(40px);
                                @extend .fw-900;
                                -webkit-text-stroke: 2px #000;
                                
                                @include setWidthRwd(1100px,1700px){
                                    @include fontSize(30px);
                                }

                                @include setMaxRwd{
                                    @include fontSize(30px);
                                }
                            }
    
                            &-img-top{
                                @include setSize(get-vw(400px),0);
                                margin-bottom: 0;
                                img{
                                    @extend %imgCenter;
                                    bottom: auto;
                                }

                                @include setStyleMinRwd(760px){
                                    padding-bottom: 100%;
                                    margin-left: auto;
                                }

                                @include setStyleRwd(759px){
                                    @include setSize(auto,auto);
                                    margin-top: 10px;
                                    text-align: center;
                                    img{
                                        position: static;
                                        margin: 0;
                                    }
                                }
                            }
    
                            &-time{
                                font-size: 82.5%;
                                margin-bottom: 0.15rem;
                            }
    
                            &-body{
                                @include setStyleMinRwd(760px){
                                    position: absolute;
                                    bottom: 10px;
                                    z-index: 1;
                                    width: get-vw-mobile(140px);
                                }

                                @include setMinRwd{
                                    width: get-vw(270px);
                                }
                            }
    
                            &::before{
                                content: '';
                                display: block;
                                @include setSize(1px,100%);
                                background-color: $colorBase;
                                position: absolute;
                                left: get-vw(-75px);
                            }
    
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(1px,get-vw(200px));
                                background-color: $colorBase;
                                position: absolute;
                                right: get-vw(-75px);
                                bottom: 0;
                            }
    
                            @include setMinRwd{
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }

                        @include setStyleMinRwd(700px){
                            transform: scale(1.1) !important;
                        }
                    }
                }

                &-pagination{
                    bottom: get-vw(10px) !important;
                    @include setStyleMinRwd(760px){
                        width: get-vw(380px) !important;
                        padding-left: get-vw(50px);
                        bottom: get-vw(0px) !important;
                    }
                }
            }
        }

        //
        &-prod{
            margin-top: get-vw(20px);
            padding-bottom: get-vw(20px);
            @include setMinRwd{
                margin-left: -1em;
                margin-right: -1em;
            }
            
            .swiper{
                &-pagination{
                    @include setStyleMinRwd(760px){
                        justify-content: flex-end;
                        padding-right: get-vw(50px);
                    }
                }
            }
        }
    }
}

/* swiper */
.fancy-slider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: $font-family_1;
	--fancy-slider-title-font-size: 88px;
	.swiper {
		width: 100%;
		height: 100%;
		transition-duration: var(--fancy-slider-transition-background-duration);
		transition-property: background-color;
		transition-delay: var(--fancy-slider-transition-background-delay);
	}
	.swiper-wrapper {
		transition-delay: var(--fancy-slider-transition-slide-delay);
		transition-timing-function: var(--fancy-slider-transition-timing-function);
	}
	.swiper-slide {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

    &Swiper{
        &-sec04{
            align-items: flex-end !important;
            justify-content: flex-end !important;
            .fancy-slider-title-head {
                @include setMinRwd{
                    top: get-vw(-110px);
                    left: get-vw(-240px);
                }
            }
        }
    } 
}

.fancy-slider-no-transition {
	transition-duration: 0ms !important;
	transition-delay: 0ms !important;
	transition-property: none !important;
	* {
		&:not(.swiper-wrapper) {
			transition-duration: 0ms !important;
			transition-delay: 0ms !important;
			transition-property: none !important;
		}
	}
	.swiper-wrapper {
		transition-delay: 0ms !important;
	}
}

.fancy-slider-title {
	transition-delay: var(--fancy-slider-transition-title-delay);
	z-index: 10;
    &-head{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: get-vw(40px);
        margin-bottom: get-vw(20px);
        transition-delay: var(--fancy-slider-transition-title-delay);

        @include setMinRwd{
            left: get-vw(190px);
        }
    }

    &-text {
        font-family: $Poppins;
        font-weight: 900;
        font-size: get-vw-mobile(35px);
        line-height: var(--fancy-slider-title-line-height);
        font-family: var(--fancy-slider-title-font-family);
        letter-spacing: -1px;
        color: $color_1;
        -webkit-text-stroke: 2px #fff;

        @include setMinRwd{
            font-size: get-vw(75px);
        }

        @include setMaxRwd{
            color: rgba(255,255,255,1) !important;
        }
    }

    &-subtext{
        font-family: $Poppins;
        letter-spacing: 3px;
        font-size: get-vw-mobile(16px);
        margin-top: 10px;
        color: $color_1;
        -webkit-text-stroke: 2px #fff;
        @include setMinRwd{
            font-size: get-vw(22px);
        }
    }
}

.fancy-slider-scale {
	width: 100%;
	height: 100%;
	position: absolute;
	transform: scale(var(--fancy-slider-transform-scale-slide));
	transition-property: transform;
	transition-timing-function: var(--fancy-slider-transition-timing-function);
	opacity: 0.9;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scale(var(--fancy-slider-transform-scale-img));
		transition-timing-function: var(--fancy-slider-transition-timing-function);
		transition-property: transform;
	}
}

.fancy-slider-button {
	transition: var(--fancy-slider-transition-button);
	outline: none;
	position: absolute;
	width: 140px;
	z-index: 10;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;

    @include setMaxRwd{
        width: 100px;
    }

	svg {
		display: block;
		transition: var(--fancy-slider-transition-button);
	}
	.fancy-slider-svg-circle-wrap {
		transition: var(--fancy-slider-transition-button-circle);
		transform-origin: -20px 40px;
		opacity: 1;
	}
	circle {
		transition: var(--fancy-slider-transition-button-circle);
		stroke-width: 2px;
		stroke: #fff;
		fill: none;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		opacity: 1;
		transform-origin: 0px 0px 0px;
	}
	.fancy-slider-svg-arrow {
		transition: var(--fancy-slider-transition-button-arrow);
		fill: #fff;
		// transform: rotateY(180deg) translate(-55px, 36.1px) scale(1.75);
        // transform: rotateY(180deg) translate(-45px, 28.1px) scale(3);
        transform: rotateY(180deg) translate(-49px, 31.1px) scale(3)
	}
	.fancy-slider-svg-wrap {
		transform: translateY(353px);
	}
	.fancy-slider-svg-line {
		transition: var(--fancy-slider-transition-button);
		stroke: #fff;
		stroke-width: 2;
		transform: translate(50px, 42px);
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			// .fancy-slider-svg-circle-wrap {
			// 	transform: scale(1.1);
			// }
			circle {
				stroke-dasharray: 4px;
				stroke-dashoffset: 4px;
				opacity: 1;
			}
			// .fancy-slider-svg-arrow {
			// 	transform: rotateY(180deg) translate(-40px, 36.1px) scale(1.75);
			// }
			// .fancy-slider-svg-line {
			// 	transform: translate(35px, 42px) scaleX(0.5);
			// }
		}
	}
}

.fancy-slider-button-prev {
	left: var(--fancy-slider-button-side-offset);
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(-16px);
			}
		}
	}
}

.fancy-slider-button-next {
	right: var(--fancy-slider-button-side-offset);
	.fancy-slider-svg-wrap {
		transform: translateY(353px) rotateY(180deg);
		transform-origin: 80px 0px 0px;
	}
	&:not(.fancy-slider-button-disabled) {
		&:hover {
			svg {
				transform: translate(16px);
			}
		}
	}
}

.fancy-slider-button-disabled {
	opacity: 0.2;
	cursor: default;
}

.fancy-slider{
    .swiper {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
    }

    .swiper-vertical {
        >.swiper-wrapper {
            flex-direction: column;
        }
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        transform: translate(0);
    }

    .swiper-android {
        .swiper-slide {
            transform: translate(0);
        }
    }

    .swiper-pointer-events {
        touch-action: pan-y;
    }

    .swiper-pointer-events.swiper-vertical {
        touch-action: pan-x;
    }

    .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
    }

    .swiper-slide-invisible-blank {
        visibility: hidden;
    }

    .swiper-autoheight {
        height: auto;
        .swiper-slide {
            height: auto;
        }
        .swiper-wrapper {
            align-items: flex-start;
            transition-property: transform, height;
        }
    }

    .swiper-backface-hidden {
        .swiper-slide {
            transform: translateZ(0);
            backface-visibility: hidden;
        }
    }

    .swiper-3d {
        perspective: 1200px;
        .swiper-wrapper {
            transform-style: preserve-3d;
        }
        .swiper-slide {
            transform-style: preserve-3d;
        }
        .swiper-slide-shadow {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background: rgba(0, 0, 0, 0.15);
        }
        .swiper-slide-shadow-left {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-right {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-top {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-slide-shadow-bottom {
            transform-style: preserve-3d;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 10;
            background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) );
        }
        .swiper-cube-shadow {
            transform-style: preserve-3d;
        }
    }

    .swiper-3d.swiper-css-mode {
        .swiper-wrapper {
            perspective: 1200px;
        }
    }

    .swiper-css-mode {
        >.swiper-wrapper {
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
            >.swiper-slide {
                scroll-snap-align: start start;
            }
        }
    }

    .swiper-horizontal.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: x mandatory;
        }
    }

    .swiper-vertical.swiper-css-mode {
        >.swiper-wrapper {
            scroll-snap-type: y mandatory;
        }
    }

    .swiper-centered {
        >.swiper-wrapper {
            &:before {
                content: "";
                flex-shrink: 0;
                order: 9999;
            }
            >.swiper-slide {
                scroll-snap-align: center center;
            }
        }
    }

    .swiper-centered.swiper-horizontal {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-inline-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                height: 100%;
                width: var(--swiper-centered-offset-after);
            }
        }
    }

    .swiper-centered.swiper-vertical {
        >.swiper-wrapper {
            >.swiper-slide {
                &:first-child {
                    margin-block-start: var(--swiper-centered-offset-before);
                }
            }
            &:before {
                width: 100%;
                height: var(--swiper-centered-offset-after);
            }
        }
    }

    @media (max-width: 480px) {
        .fancy-slider {
            --fancy-slider-title-font-size: 60px;
        }
    }

    @media (max-width: 640px) {
        // .fancy-slider-button {
        //     top: auto;
        //     bottom: var(--fancy-slider-button-mobile-bottom-offset);
        //     transform: none;
        // }
        .fancy-slider-button-prev {
            left: var(--fancy-slider-button-mobile-side-offset);
        }
        .fancy-slider-button-next {
            right: var(--fancy-slider-button-mobile-side-offset);
        }
    }
}

