.about{
    &-page-mainWrapper{
        margin-left: -15px;
        margin-right: -15px;
        &::before{
            max-height: get-vw(870px);
            @include setWidthRwd(1100px,1400px){
                max-height: get-vw(1070px);
            }

            @include setMaxRwd{
                max-height: get-vw-mobile(900px);
            }

            @include mobile{
                max-height: get-vw-mobile(450px);
            }
        }
    }

    &-content{
        @include setMinRwd{
            display: flex;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    &-intro{
        align-items: center;

        @include setMinRwd{
            margin-bottom: get-vw(140px);
        }

        @include setMaxRwd{
            margin-bottom: 1rem;
        }

        &-img{
            @include setMinRwd{
                flex:0 0 get-vw(970px);
                margin-right: - calc((975 / 5) / 1500 * 100%);
            }

            @include setWidthRwd(1100px,1400px){
                margin-right: get-vw(-20px)
            }
        }

        &-txt{
            &-subHead{
                @include fontSize(24px);
                margin-bottom: 1.25rem;
            }

            &-head{
                @include fontSize(36px);
            }

            &-des{
                padding-top: get-vw(45px);
                padding-bottom: get-vw(70px);
                line-height: 2;
            }

            @include setMinRwd{
                padding-right: get-vw(65px);
            }
        }
    }

    &-card-wrap{
        .card{
            background-color: transparent;

            &-img-top{
                @include setMinRwd{
                    flex: 0 0 calc(515 / 1500 * 100%);
                    max-width: calc(515 / 1500 * 100%);
                }
                img{
                    width: auto;
                }
            }

            &-body{
                @include setMinRwd{
                    flex: 0 0 calc(100% - (515 / 1500 * 100%));
                    max-width: calc(100% - (515 / 1500 * 100%));
                    padding-left: get-vw(160px);
                }
            }

            &-title{
                position: relative;
                @include fontSize(25px);
                &::before{
                    content: '';
                    display: block;
                    @include setSize(10px,10px);
                    background-color: $colorItem;
                    @include radius(50%);
                    position: absolute;
                    top: 0rem;
                    left: -1rem;
                }

                @include setMinRwd{
                    @include fontSize(30px);
                    &::before{
                        left: -2rem;
                    }
                }
            }

            &-des{
                line-height: 2;
                padding-top: 5px;
            }

            &-body{
                @include setMinRwd{
                    display: inline-flex;
                    .card-title{
                        flex: none;
                        padding-right: get-vw(70px);
                    }
                }
            }

            &-img-big{
                flex-grow: 1;
                max-width: 100%;
            }

            &-body-big{
                flex: 1.35;
                @include setMinRwd{
                    padding-left: 2rem;
                }

                @include setMaxRwd{
                    padding-bottom: 0;
                }
            }

            &-reverse{
                @include setMinRwd{
                    .card-img-top{
                        order: 1;
                        flex: 0 0 calc(580 / 1500 * 100%);
                        max-width: calc(580 / 1500 * 100%);
                    }
                    .card-body{
                        flex: 0 0 calc(100% - (580 / 1500 * 100%));
                        max-width: calc(100% - (580 / 1500 * 100%));
                        padding-left: get-vw(60px);
                        padding-right: get-vw(60px);
                    }
                }
            }

            @include setMinRwd{
                display: inline-flex;
                flex-flow: row wrap;
                align-items: center;

                &-sec-01{
                    margin-top: get-vw(-120px);
                    margin-bottom: get-vw(70px);
                }

                &-sec-02{
                    margin-top: get-vw(-50px);
                    .card-img-top{
                        margin-top: get-vw(-100px);
                    }
                    .card-des{
                        padding-right: 1rem;
                    }
                }
                
                &-sec-03{
                    margin-top: get-vw(100px);
                }
            }

            @include setMaxRwd{
                flex: 0 0 calc(100% - 1em);
                max-width: calc(100% - 1em);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}