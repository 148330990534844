footer {
  padding: 1em;
  background-color: $colorMain;

  &,a{
    color: #fff;
  }

  .footer {
    &-info{
      text-align: center;
      p{
        margin-bottom: 8px;
      }

      @include setMaxRwd{
        @include fontSize(14px)
      }
    }
  }

  @include setMinRwd{
    padding: 50px 25px;
  }

  @include setMaxRwd{
    margin-bottom: 1.75em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
  &, a{
    color: #f5b2aa;
  }
  
  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 15px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: $colorSubs;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      padding: 6px;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        small{
          font-size: 100%;
        }
      }
    }
  }
}