@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
a, body {
  color: #222222;
  font-size: 16px;
  font-family: "Poppins", sans-serif, "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.25;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.index-pageWrapper, .card-wrap, .box, .pagination-wrap, .mainContent {
  display: flex;
  flex-wrap: wrap;
}

.pro-searchForm, .pro-searchList, .pro-searchList li, .pro-linkWrap, .member-editor-buildCase-tab .btn-wrap .btn, .member-editor-buildCase-tab, .card-time, .btn-wrap, .heading-title-wrap, .title-mainWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.page-gallerSwiper-news .swiper-slide-active .card-img-top img, .card-wrap-mainLink .card-img-top img, .imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1100px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650, .index-pagePro-swiper-box {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
@media (min-width: 1101px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
  .start-header.scroll-on .search-formList {
    top: 105px;
  }
}
.start-header .container {
  max-width: 1880px;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (max-width: 1100px) {
  .navigation-wrap {
    padding: 5px 0;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
  padding: 0;
}
@media (min-width: 1550px) and (max-width: 1700px) {
  .navbar .navbar-brand {
    max-width: 13em;
  }
}
@media (min-width: 1050px) and (max-width: 1500px) {
  .navbar .navbar-brand {
    max-width: 10em;
  }
  .navbar .nav-item-search .nav-link {
    padding-top: 8px !important;
  }
}
@media (min-width: 1050px) and (max-width: 1300px) {
  .navbar .nav-item {
    margin-left: 0.5em !important;
  }
  .navbar .nav-item-search .nav-link {
    padding-top: 6px !important;
  }
}
@media (min-width: 1070px) and (max-width: 1150px) {
  .navbar {
    flex-direction: column;
  }
}
@media (min-width: 1000px) and (max-width: 1020px) {
  .navbar {
    align-items: flex-start;
  }
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (min-width: 1600px) {
  .navbar-brand {
    margin-right: 3em;
  }
}
@media (max-width: 1080px) {
  .navbar-brand {
    margin-left: -0.5rem;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-collapse {
    padding-top: 25px;
  }
}
@media (min-width: 800px) and (max-width: 1500px) {
  .navbar-collapse {
    width: 100%;
    margin-top: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1101px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none;
  position: fixed;
  left: 59%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block;
}
.navbar-nav.link {
  flex: 1;
  justify-content: space-between;
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 15px;
  font-size: 0.9375rem;
  background-color: transparent !important;
  white-space: normal;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
  color: #ef8586 !important;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1100px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.link {
    position: relative;
    flex: none;
    flex-basis: calc(740px - 2em);
    margin-left: auto;
    padding-right: 1em;
  }
  .navbar-nav.link .nav-link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    position: static;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    background: #fff;
    max-height: 100vw;
    left: -50%;
    top: 3.4em;
    padding: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
    z-index: 1;
    min-width: 70vw;
    max-width: 100%;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu::before {
    content: "";
    display: block;
    width: 1000%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: -50%;
    top: 14px;
    z-index: -1;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu::after {
    content: "";
    width: 88.4210526316vw;
    height: 1px;
    background-color: #eee;
    display: block;
    position: absolute;
    top: 14px;
    left: -34.5%;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    min-width: 70em;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .navbar-nav.link {
    flex: 1;
    margin: auto;
    padding-right: 1em;
    justify-content: space-around;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-search, .navbar-nav.info > li.nav-item-social {
    display: none;
  }
  .navbar-nav.info > li.nav-item-lang {
    flex: 0 0 calc(100% - 3rem);
    max-width: calc(100% - 3rem);
    margin-top: 0.5rem;
    order: 1;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #696969 !important;
  font-weight: 500;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1101px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #ef8586 !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-search {
  font-size: 1.5em;
  margin-top: 2.5px;
}
.nav-item .icon .bi-caret-down-fill {
  margin-left: 5px;
  font-size: 14px;
}
.nav-item img {
  margin: 0 !important;
}
@media (min-width: 1701px) and (max-width: 1800px) {
  .nav-item {
    margin-left: 1.35em !important;
  }
}
@media (min-width: 1201px) and (max-width: 1700px) {
  .nav-item {
    margin-left: 1.25em !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -2em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 19px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
  .nav-item.nav-item-lang .nav-link .icon:last-child {
    display: none !important;
  }
  .nav-item.nav-item-lang .dropdown-menu {
    position: relative;
    opacity: 1;
    max-height: 100%;
    display: flex;
    visibility: visible;
    padding: 0 !important;
    transform: translate3d(0, 5px, 0);
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-size: 1rem;
    padding: 5px 0px;
    text-align: center;
    line-height: 2;
    background-color: #ef8586;
    color: #fff;
    border-radius: 0;
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item + .dropdown-item {
    margin-left: 4px;
  }
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #ef8586;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 9.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(-10px, 0px, 0);
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu {
    padding-top: 0 !important;
  }
  .nav-item .dropdown-menu .dropdown-menu-list {
    padding-left: 10px;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.5em;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li .navbar-mobile-icon {
    right: -2em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item-lang .dropdown-toggle {
    display: none !important;
  }
}

@media (max-width: 1080px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    width: 11em;
    justify-content: flex-end;
  }
  .nabar-icon-mobile .icon {
    margin-left: 5px;
  }
  .nabar-icon-mobile .icon-search {
    margin-right: 4px;
    margin-top: 3px;
  }
}

.search-formList {
  position: absolute;
  top: 83px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .search-formList {
    top: 10.3em;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .search-formList {
    top: 8.75em;
  }
}
@media (max-width: 810px) {
  .search-formList {
    top: 5.2em;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  background: #ef8586;
  border: none;
  cursor: pointer;
}

footer {
  padding: 1em;
  background-color: #ef8586;
}
footer, footer a {
  color: #fff;
}
footer .footer-info {
  text-align: center;
}
footer .footer-info p {
  margin-bottom: 8px;
}
@media (max-width: 1100px) {
  footer .footer-info {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 1101px) {
  footer {
    padding: 50px 25px;
  }
}
@media (max-width: 1100px) {
  footer {
    margin-bottom: 1.75em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware, .iware a {
  color: #f5b2aa;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1101px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1100px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1100px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #f5b2aa;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    padding: 6px;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
  }
}

.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

@media (min-width: 1600px) {
  .in-content {
    padding-top: 2.6315789474vw;
    padding-bottom: 2.6315789474vw;
  }
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
.g-recaptcha iframe {
  height: auto !important;
}

.fw-900, .Prod-headingTitle, .Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after, .page-gallerSwiper-news .swiper-slide-active .card-title, .index-pageBlog-subtitle, .index-pageHead, .card-btn-more, .card-time-date, .heading-title, .title-ch, .title-en, .link-head {
  font-weight: 900 !important;
}

.boxShadow, .Prod-table, .member-content .form-box, .card-wrap-mainLink .card:hover .card-img-top, .card-wrap-mainNews .card:hover, .card-wrap-indexNews .card {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1100px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1101px) {
  .mainContent-info {
    width: 440px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 440px);
    padding-left: 140px;
    padding-right: 100px;
  }
}
@media (min-width: 1000px) and (max-width: 1500px) {
  .mainContent-info {
    width: 300px;
    padding-left: 1em;
    padding-right: 1em;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 300px);
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .mainContent-info .headingLink-title,
.mainContent-info .link-wrap {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1100px) {
  .mainContent-list {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.link-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 10px 20px 0;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  line-height: 1.5;
  color: #222222;
  padding: 0.75em 50px 0.75rem 0.75rem;
}
.link-wrap ul > li > a::after {
  content: "";
  font-family: "bootstrap-icons";
  display: block;
  color: #000;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -15px;
  font-weight: 100;
  font-size: 22px;
  font-size: 1.375rem;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  color: #ef8586;
}
.link-wrap ul > li > ol a {
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.75);
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
.link-wrap ul > li > ol a::before {
  content: "\f054";
  font-family: FontAwesome;
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-size: 0.75rem;
  display: block;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
.link-wrap ul > li > ol li {
  background-color: #ef8586;
  border-bottom: #fff 1px dotted;
  padding-left: 20px;
}
.link-wrap ul > li > ol li:hover a, .link-wrap ul > li > ol li:hover a::before, .link-wrap ul > li > ol li.active a, .link-wrap ul > li > ol li.active a::before {
  color: rgb(255, 255, 255);
}
.link-wrap.pro {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d9d9d9+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, #ffffff 0%, #d9d9d9 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 0%, #d9d9d9 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 0%, #d9d9d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#d9d9d9",GradientType=1 ); /* IE6-9 */
}
.link-wrap.pro ul > li > a {
  font-size: 20px;
  font-size: 1.25rem;
}
.link-wrap.pro ul > li > a::after {
  content: "\f4fe";
}
.link-wrap.pro ul > li > a:hover::after, .link-wrap.pro ul > li > a.active::after {
  content: "\f2ea";
}
.link-wrap.quick, .link-wrap.asideUse {
  background: -moz-linear-gradient(left, #ffffff 0%, #f4f4f4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 0%, #f4f4f4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 0%, #f4f4f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#f4f4f4",GradientType=1 ); /* IE6-9 */
  margin-top: 2em;
}
.link-wrap.quick ul > li, .link-wrap.asideUse ul > li {
  margin-bottom: 0;
}
.link-wrap.quick ul > li > a, .link-wrap.asideUse ul > li > a {
  font-size: 16px;
  font-size: 1rem;
  color: #747474;
  display: flex;
  align-items: center;
  padding-right: 0;
}
.link-wrap.quick ul > li > a::after, .link-wrap.asideUse ul > li > a::after {
  content: "\f280" !important;
  position: static;
  margin: 4px 10px 0;
  font-size: 12px;
  font-size: 0.75rem;
  opacity: 0;
}
.link-wrap.quick ul > li > a:hover, .link-wrap.quick ul > li > a.active, .link-wrap.asideUse ul > li > a:hover, .link-wrap.asideUse ul > li > a.active {
  color: #000;
}
.link-wrap.quick ul > li > a:hover::after, .link-wrap.quick ul > li > a.active::after, .link-wrap.asideUse ul > li > a:hover::after, .link-wrap.asideUse ul > li > a.active::after {
  opacity: 1;
}
.link-wrap.asideUse {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d9d9d9+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, #ffffff 0%, #d9d9d9 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 0%, #d9d9d9 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 0%, #d9d9d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#d9d9d9",GradientType=1 ); /* IE6-9 */
}
.link-wrap.asideUse ul > li > a {
  font-size: 20px;
  font-size: 1.25rem;
  color: #000;
}
.link-wrap .first_menu {
  padding-left: 1.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 0.25rem;
  background: transparent;
  z-index: 1;
}
.link-wrap .first_menu::after {
  content: "";
  display: block;
  width: 100%;
  min-width: 400px;
  height: 100%;
  background: -moz-linear-gradient(left, #ffffff 0%, #f4f4f4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 0%, #f4f4f4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 0%, #f4f4f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#f4f4f4",GradientType=1 ); /* IE6-9 */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.link-wrap .first_menu a {
  color: #7e7e7e !important;
}
.link-wrap .first_menu a.active {
  color: #000 !important;
}
.link-wrap .first_menu.open {
  display: block;
}
@media (min-width: 1101px) {
  .link-wrap .first_menu::after {
    left: -25.5%;
  }
}
@media (min-width: 1101px) {
  .link-wrap {
    padding-left: 80px;
  }
  .link-wrap .first_menu {
    display: none;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap {
    padding-left: 1em;
  }
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
  .link-wrap .first_menu::after {
    min-width: 17em;
  }
}
@media (max-width: 1100px) {
  .link-wrap.quick {
    border-radius: 20px;
    padding: 15px 20px;
    margin-top: 0;
    background: #f4f4f4;
  }
  .link-wrap.quick .link-head {
    padding-left: 0;
    margin-bottom: 0.5rem;
  }
  .link-wrap.quick ul {
    display: flex;
    flex-wrap: wrap;
  }
  .link-wrap.quick ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .link-wrap.quick ul li > a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.25;
    padding: 0.5em 0.5em 0.25em 0;
  }
  .link-wrap.quick ul li > a::after {
    display: none;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 30px;
  font-size: 1.875rem;
  color: #ef8586;
  padding-left: 0.75rem;
  margin-bottom: 1.5rem;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1100px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.title-wrap .btn .icon {
  font-size: 23px;
  font-size: 1.4375rem;
}
@media (max-width: 1100px) {
  .title-wrap {
    margin-bottom: 2px;
  }
}
.title-en {
  font-family: "Mitr", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1100px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.titleStyle {
  position: relative;
  padding: 0.25em;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #c49d36;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: 8px;
}
.title__topLine {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #000;
}
.title__solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.title-left-circle {
  position: relative;
  padding-left: 30px;
  z-index: 1;
}
.title-left-circle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: #ef8586 2px solid;
  border-radius: 50%;
  top: 11px;
  left: 10px;
  z-index: -1;
}
.title-right-squre {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-right-squre::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #0125fd;
  position: absolute;
  bottom: 2px;
  right: -40px;
  z-index: -1;
}
.title__topHead::before {
  content: "";
  display: block;
  width: 30px;
  height: 0.5rem;
  background-color: #ef8586;
  margin-bottom: 1rem;
}

.heading-title {
  font-size: 1.8421052632vw;
}
@media (max-width: 640px) {
  .heading-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
@media (min-width: 1101px) {
  .heading-title-wrap {
    flex-wrap: nowrap;
  }
}

.breadcrum-wrap {
  margin-top: 0.7894736842vw;
  margin-bottom: 0.7894736842vw;
}
@media (max-width: 1100px) {
  .breadcrum-wrap {
    margin-top: 2.34375vw;
    margin-bottom: 2.34375vw;
  }
}
.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f285";
  font-family: "bootstrap-icons" !important;
  padding-right: 0.65em;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #ef8586;
}
@media (max-width: 1100px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: 0px;
  margin-right: 2px;
}
.breadcrum-wrap .icon img {
  vertical-align: initial;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 45px;
  height: 45px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 45px;
  color: #6e6d6d;
  font-family: "Roboto";
  background-color: #fff;
  border-color: #7d7d7d;
  border-radius: 0;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.pagination-wrap .pagination-select {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select .select_head {
  flex: none;
  padding-right: 10px;
}
.pagination-wrap .pagination-select .select_style {
  width: 100%;
  min-height: auto;
}
.pagination-wrap .pagination-select .select_style::after {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1100px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main {
  color: #ef8586 !important;
}
.text-itemSub {
  color: #0efff1 !important;
}
.text-underLine, .index-content .btn_link {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .btn-tag, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .btn-tag ~ .tag-item, .tag-item-wrap .tag-item ~ .btn-tag, .tag-item-wrap .btn-tag ~ .btn-tag, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-current ~ .btn-tag, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item-date ~ .btn-tag, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .btn-tag ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .btn-tag ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1101px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1100px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.page-mainWrapper {
  position: relative;
  padding: 15px;
}
.page-mainWrapper::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  max-height: 27.6315789474vw;
  background-color: #f1f0f0;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .page-mainWrapper::before {
    max-height: 34.2105263158vw;
  }
}
@media (max-width: 1100px) {
  .page-mainWrapper::before {
    max-height: 55.46875vw;
  }
}
@media (max-width: 380px) {
  .page-mainWrapper::before {
    max-height: 62.5vw;
  }
}
@media (min-width: 1101px) {
  .page-mainWrapper {
    padding-bottom: 0;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .page-mainWrapper {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
}
.page-mainTxt-banner {
  padding-top: 6.5789473684vw;
  padding-bottom: 4.4736842105vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-mainTxt-banner-des, .page-mainTxt-banner-head {
  display: inline-block;
  font-weight: 100;
}
.page-mainTxt-banner-des {
  font-size: 30px;
  font-size: 1.875rem;
  color: #0b3a5b;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 1100px) {
  .page-mainTxt-banner-des {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.page-mainTxt-banner-head {
  position: relative;
  display: flex;
  font-size: 150px;
  font-size: 9.375rem;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}
.page-mainTxt-banner-head::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #c49d36;
  border-radius: 50%;
  position: relative;
  top: 2.2rem;
  right: -2.2rem;
}
@media (max-width: 1100px) {
  .page-mainTxt-banner-head {
    font-size: 70px;
    font-size: 4.375rem;
  }
  .page-mainTxt-banner-head::after {
    width: 20px;
    height: 20px;
    top: 3.2rem;
    right: -1rem;
  }
}
.page-mainTxt-banner-arrowBottom {
  color: #595959;
  padding-top: 1.3157894737vw;
  padding-bottom: 1.3157894737vw;
}
@media (max-width: 1100px) {
  .page-mainTxt-banner {
    padding-top: 3.9473684211vw;
  }
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label_other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1101px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn-item:hover, .btn:hover {
  background-color: #ef8586 !important;
  border-color: #ef8586 !important;
  color: #fff !important;
}

.btn-item:hover, .btn:hover {
  background-color: #ef8586 !important;
  border-color: #ef8586 !important;
  color: #fff !important;
}

.btn-main:hover {
  background-color: #c49d36 !important;
  border-color: #c49d36 !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
  border-radius: 0 !important;
  padding: 0.35em 1.5em 0.5em;
}
@media (max-width: 1100px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .fa {
  font-size: 0.6em;
  font-weight: 100;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 6px;
}
.btn .icon {
  vertical-align: top;
  margin-top: 0px;
  margin-right: 3px;
}
.btn-main {
  border: 1px solid #ef8586 !important;
  background-color: #ef8586 !important;
  color: #fff !important;
}
.btn-item {
  border: 1px solid #c49d36;
  background-color: #c49d36;
  color: #fff;
}
.btn-medium {
  padding: 0.75rem 2em !important;
}
.btn-big {
  padding: 1.15rem 3em !important;
}
.btn-small {
  padding: 0.25rem 0.5rem !important;
  font-size: 16px !important;
}
.btn-tag {
  padding: 6px 12px !important;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.25;
}
.btn-noRadius {
  border-radius: 0;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-white {
  background-color: #fff;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1101px) {
  .card__2 {
    flex: 0 0 calc(50% - 2em);
    max-width: calc(50% - 2em);
  }
}
@media (min-width: 1101px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1101px) {
  .card__4 {
    flex: 0 0 calc(25% - 2em);
    max-width: calc(25% - 2em);
  }
}
@media (max-width: 1100px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1101px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1101px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #ef8586 !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 1101px) {
  .card-wrap-indexNews .card-title {
    font-size: 20px;
    font-size: 1.25rem;
    margin-bottom: 0.5em;
  }
}
.card-wrap-indexNews .card-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1101px) {
  .card-wrap-indexNews .card-body {
    padding-left: 3em;
    padding-right: 1.75em;
    padding-top: 2em;
  }
}
.card-wrap-indexNews .card-text {
  min-height: 50px;
  margin-bottom: 1.5em;
}
@media (max-width: 1100px) {
  .card-wrap-indexNews .card-text {
    height: 100%;
  }
}
@media (max-width: 1100px) {
  .card-wrap-indexNews .card {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1101px) {
  .card-wrap-indexNews {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
.card-wrap-indexApplication .card {
  border-radius: 0.875em;
  overflow: hidden;
}
.card-wrap-indexApplication .card-img-top {
  margin-bottom: 5px;
}
.card-wrap-indexApplication .card-img-top img {
  display: block;
  width: auto;
  margin: auto;
}
.card-wrap-indexApplication .card-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 100;
  min-height: 40px;
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media (min-width: 1101px) {
  .card-wrap-indexApplication {
    margin-top: 80px;
  }
  .card-wrap-indexApplication .card {
    flex: 0 0 calc(16.6666666667% - 1em);
    max-width: calc(16.6666666667% - 1em);
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
.card-wrap-mainNews .card {
  background-color: #f5f4f4;
  box-shadow: none !important;
}
.card-wrap-mainNews .card:hover {
  background-color: #fff;
}
.card-wrap-mainLink .card:hover .card-img-top {
  background-color: #fff;
}
@media (min-width: 1101px) {
  .card-wrap-mainLink .card {
    background-color: transparent;
    box-shadow: none !important;
    margin-bottom: 3em;
  }
  .card-wrap-mainLink .card:hover .card-title {
    font-weight: 600;
    text-decoration: underline;
  }
}
@media (max-width: 1100px) {
  .card-wrap-mainLink .card {
    padding-top: 0.5em;
  }
  .card-wrap-mainLink .card:hover .card-img-top {
    box-shadow: none !important;
  }
}
.card-wrap-mainLink .card-img-top {
  text-align: center;
  padding: 1.5em 1em 25%;
}
.card-wrap-mainLink .card-img-top img {
  width: auto;
}
@media (max-width: 1100px) {
  .card-wrap-mainLink .card-img-top {
    padding-bottom: 42.5%;
  }
}
@media (max-width: 1100px) {
  .card-wrap-mainLink .card-body {
    padding-top: 0;
  }
}
.card-wrap-mainLink .card-title {
  font-weight: 100;
}
@media (max-width: 640px) {
  .card-wrap-mainLink .card-title {
    font-size: 16px;
    font-size: 1rem;
    text-align: center;
    margin-top: 0;
  }
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1100px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

.activity_Swiper {
  padding: 0 4em;
}
.activity_Swiper .swiper-button-next, .activity_Swiper .swiper-button-prev {
  color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: #d0d0d0 1px solid;
}
.activity_Swiper .swiper-button-next::after, .activity_Swiper .swiper-button-prev::after {
  font-size: 1.5rem;
}
.activity_Swiper .swiper-button-next {
  right: 0;
}
.activity_Swiper .swiper-button-prev {
  left: 0;
}

@media (min-width: 1101px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1101px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}
.form-box .title {
  text-align: center;
  padding: 20px 0 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #ef8586;
}
.form-box .section {
  padding: 20px 0;
}
.form-box .form-control {
  height: 50px;
  border-radius: 0;
}
.form-box .message {
  display: block;
  width: 100%;
  margin: 20px 0 0;
  font-size: 16px;
  text-align: right;
}
.form-box .message a {
  position: relative;
  display: inline-block;
  width: 25%;
  color: #636262;
  text-decoration: none;
  text-align: left;
  border-bottom: #ccc 1px solid;
  padding-bottom: 10px;
}
.form-box .message a::after {
  content: "→";
  display: inline-block;
  float: right;
}
.form-box.forget {
  display: none;
}
.form-box .form-list p {
  margin-bottom: 0;
}
.form-box.regis .form-group {
  display: flex;
  align-items: center;
}
.form-box.regis .form-group > label {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.form-box.regis .form-group .form-list {
  flex: 1;
  display: inline-flex;
  align-items: center;
}
.form-box.regis .form-group .form-list label {
  margin-bottom: 0;
}
.form-box.regis.regis-form .form-group {
  align-items: normal;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
}
.form-box.regis.regis-form .form-group > label {
  min-width: 200px;
  background: #f0f0f0;
  padding: 10px;
}
.form-box.regis.regis-form .form-group .form-list {
  padding: 5px 10px;
}
.form-box.regis.regis-form .form-group .form-list .form-control {
  margin-bottom: 5px;
}
@media (max-width: 810px) {
  .form-box.regis.regis-form .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-box.regis.regis-form .form-group > label {
    width: 100%;
    flex-direction: row !important;
    justify-content: flex-start;
    font-size: 16px;
    font-size: 1rem;
  }
  .form-box.regis.regis-form .form-group .form-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .form-box.regis.regis-form .form-group .radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .form-box.regis.regis-form .form-group .btn {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 0 !important;
  }
  .form-box.regis.regis-form .form-group .form-input .code {
    width: inherit;
  }
}
.form-status {
  margin-bottom: 1.5rem;
}
.form-status-link .icon {
  margin-top: -3px;
}

.member-content {
  padding-top: 10px;
  padding-bottom: 40px;
}
.member-content .form-box {
  max-width: 650px;
  background-color: #fff;
  border-radius: 25px;
  padding: 1.5em 3em 1.5em 2em;
  overflow: hidden;
}
.member-content-intro {
  background-color: #eee;
}
.member-content-intro-wrap {
  max-width: 600px;
}
.member-content-login {
  background-color: #fff;
}
.member-content-login .form-group {
  margin-bottom: 1.5rem;
}
.member-content-login .btn-wrap {
  margin-top: 1rem;
}
@media (max-width: 1100px) {
  .member-content-login .btn-wrap {
    flex-wrap: wrap;
  }
  .member-content-login .btn-wrap > li {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 1100px) {
  .member-content {
    padding: 0em;
  }
}
.member-editor .form-box {
  padding: 5px 0;
  border-radius: 0;
}
.member-editor .form-group label {
  width: 150px;
}
.member-editor .form-group > label {
  padding-left: 0;
}
.member-editor .form-group-checkbox {
  flex-direction: row;
  padding-bottom: 0;
  margin-bottom: 0;
}
.member-editor .form-group-checkbox label {
  width: 100% !important;
}
.member-editor .form-group-checkbox .radio_style {
  margin-top: 0px !important;
}
.member-editor .form-group .uploader {
  min-width: 20em;
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1101px) {
  .member-editor .form-group .uploader {
    max-width: 20em;
  }
}
.member-editor .form-group .uploader-btn .btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media (max-width: 1100px) {
  .member-editor .form-group .uploader-btn {
    margin-top: 1em;
  }
}
.member-editor-buildCase-form {
  background: #eceae8;
  border-radius: 30px;
  border-top-left-radius: 0;
  padding: 2em;
}
.member-editor-buildCase-form .form-box {
  flex-wrap: wrap;
  background-color: transparent;
}
.member-editor-buildCase-form .pagination-wrap {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 1100px) {
  .member-editor-buildCase-form {
    border-top-right-radius: 0;
    padding: 0.75em 1.5em;
    margin-top: 0;
  }
}
.member-editor-buildCase-tab {
  justify-content: space-between;
}
.member-editor-buildCase-tab .nav,
.member-editor-buildCase-tab .nav li {
  position: relative;
  flex: 1;
}
.member-editor-buildCase-tab .nav-link {
  position: relative;
  z-index: 0;
  display: block;
  text-align: center;
  padding: 0.75rem 0.35rem !important;
  background-color: #e3e0de;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav-link {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}
.member-editor-buildCase-tab .nav li:hover > a, .member-editor-buildCase-tab .nav li.active > a {
  background-color: #eceae8;
}
.member-editor-buildCase-tab .nav::before {
  content: "";
  display: block;
  width: calc(100% - 70px);
  height: 50px;
  background-color: #e3e0de;
  position: absolute;
  top: 0px;
  left: 35px;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav {
    max-width: 68%;
  }
}
.member-editor-buildCase-tab .btn-wrap .btn {
  padding-bottom: 0.35em;
}
.member-editor-buildCase-tab .btn-wrap .btn .icon {
  margin-top: 0;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .btn-wrap {
    flex: 0 0 200px;
    margin: 0;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .btn-wrap {
    order: -1;
    justify-content: flex-end;
  }
}
.member-editor-buildCase .form-group {
  margin-bottom: 0.25rem;
}
.member-editor-buildCase .icon {
  margin-top: -6px;
}
@media (max-width: 1100px) {
  .member-editor-buildCase .form-group {
    width: 100%;
    flex-direction: row !important;
  }
}
.member-editor-buildCaseAdd .form-group {
  align-items: center;
}
.member-editor-buildCaseAdd .form-group > label {
  min-width: 210px;
  line-height: 1.2;
}
@media (min-width: 1101px) {
  .member-editor-buildCaseAdd .form-group > label {
    padding-left: 1.5rem;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-group {
    align-items: flex-start;
  }
  .member-editor-buildCaseAdd .form-group.flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-box {
    margin-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group {
    flex-direction: column !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .member-editor-buildCaseAdd .form-box > .form-group > label {
    padding-left: 0;
    padding-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group .form-input-size {
    width: 100% !important;
    flex: 1;
  }
  .member-editor-buildCaseAdd .form-box .twzipcode-style {
    width: calc(100% + 30px) !important;
  }
  .member-editor-buildCaseAdd .form-lineHref {
    margin-top: 0.75em;
    flex-direction: column;
  }
  .member-editor-buildCaseAdd .form-lineHref .form-input-size,
.member-editor-buildCaseAdd .form-lineHref .unit {
    width: 100% !important;
  }
  .member-editor-buildCaseAdd .form-lineHref .unit {
    padding-left: 0;
    padding-top: 5px;
  }
}

:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: "good-times", sans-serif;
  --fancy-slider-title-line-height: 1;
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 0.6; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1.2; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  padding-top: 1.5em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .index-content {
    padding: 1.5em 0.5em;
  }
}
.index-content .btn, .index-content .btn_link {
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1100px) {
  .index-pageWrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.index-pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.index-pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.index-pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.index-pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .index-pageGrid {
    grid-template-columns: auto !important;
  }
}
.index-pageHead {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1;
  -webkit-text-stroke: 2px #000;
}
@media (max-width: 1100px) {
  .index-pageHead {
    font-size: 35px;
    font-size: 2.1875rem;
  }
  .index-pageHead br {
    display: none;
  }
}
.index-pageArticle {
  line-height: 1.5;
}
.index-pageBlog-subtitle {
  font-size: 15px;
  font-size: 0.9375rem;
}
.index-pageBlog-title {
  font-size: 48px;
  font-size: 3rem;
  margin-top: 1.5625vw;
  margin-bottom: 4.6875vw;
}
@media (min-width: 1101px) {
  .index-pageBlog-title {
    margin-top: 0.5263157895vw;
    margin-bottom: 1.5789473684vw;
  }
}
@media (min-width: 1025px) and (max-width: 1700px) {
  .index-pageBlog-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (max-width: 1100px) {
  .index-pageBlog-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.index-pageBlog .icon {
  vertical-align: top;
  margin-top: -1px;
}
.index-pageBlog-picbox, .index-pageBlog-txtbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.index-pageBlog-txtbox {
  color: #222222;
  padding: 1.25em 1em;
}
@media (min-width: 1101px) {
  .index-pageBlog-txtbox {
    padding: 5.2631578947vw 3.6842105263vw;
  }
  .index-pageBlog-txtbox + .index-pageBlog-box {
    border-left: #e8e8e8 1px solid;
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .index-pageBlog-txtbox {
    padding: 2.1052631579vw 1.5789473684vw;
  }
}
@media (max-width: 1100px) {
  .index-pageBlog-txtbox {
    text-align: center;
    order: -1;
  }
}
.index-pageBlog-txtboxMain {
  background-color: #ef8586;
  color: #fff;
}
@media (min-width: 760px) {
  .index-pageBlog {
    margin-top: 50px;
    padding-bottom: 0;
  }
}
@media (max-width: 900px) {
  .index-pageBlog {
    margin-top: 0.25rem;
  }
}
@media (min-width: 1101px) {
  .index-pageBlog {
    margin-top: 100px;
  }
}
@media (max-width: 1100px) {
  .index-pageBlog {
    padding-left: 0;
    padding-right: 0;
  }
}
.index-pageAbout {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  background-image: url("../images/index-pageAbout.jpg");
}
@media (max-width: 1100px) {
  .index-pageAbout {
    padding-bottom: 15.625vw;
  }
}
.index-pageAbout .index-pageWrapper, .index-pageAbout .index-pageHead, .index-pageAbout .index-pageArticle {
  padding: 0.5rem;
}
@media (min-width: 1101px) {
  .index-pageAbout .index-pageWrapper {
    padding-bottom: 10.5263157895vw;
  }
}
@media (min-width: 1101px) {
  .index-pageAbout .index-pageHead {
    width: 35.1515151515%;
    padding-bottom: 15.7894736842vw;
    padding-right: 3.4210526316vw;
  }
}
@media (min-width: 1101px) {
  .index-pageAbout .index-pageArticle {
    flex: 1;
    padding: 6.0526315789vw 0 7.8947368421vw 3.4210526316vw;
    border-left: #222222 1px solid;
  }
}
@media (min-width: 760px) {
  .index-pagePro {
    padding-top: 0;
  }
}
.index-pagePro-list {
  flex: 1;
  width: 100%;
  padding-top: 4.6875vw;
}
@media (min-width: 1101px) {
  .index-pagePro-list {
    padding-top: 5.2631578947vw;
  }
}
@media (min-width: 1000px) {
  .index-pagePro-list {
    width: 50%;
  }
}
.index-pagePro-list .desbox {
  color: #222222;
  font-style: italic;
}
@media (min-width: 760px) {
  .index-pagePro-list .desbox {
    padding-top: 3.9473684211vw;
    padding-bottom: 2.1052631579vw;
  }
}
@media (max-width: 1100px) {
  .index-pagePro-list .desbox {
    text-align: center;
    padding-bottom: 9.375vw;
  }
}
@media (min-width: 760px) {
  .index-pagePro-list .videobox {
    margin-top: 1.5789473684vw;
  }
}
@media (min-width: 1000px) and (max-width: 1600px) {
  .index-pagePro-list .videobox {
    margin-top: 1.0526315789vw;
  }
}
@media (max-width: 1100px) {
  .index-pagePro-list .videobox .ratio::before {
    padding-top: var(--bs-aspect-ratio-mobile);
  }
}
@media (min-width: 760px) {
  .index-pagePro-video .desbox {
    padding-left: 1.0526315789vw;
    padding-right: 1.0526315789vw;
  }
}
@media (min-width: 1700px) {
  .index-pagePro-video .desbox {
    padding-left: 7.3684210526vw;
  }
}
.index-pagePro-swiper::before {
  content: "";
  display: block;
  width: 100%;
  height: 29.3157894737vw;
  background-color: #fff3f3;
  position: absolute;
  top: 0;
  z-index: 0;
}
@media (min-width: 1000px) and (max-width: 1600px) {
  .index-pagePro-swiper::before {
    width: 100%;
    height: 35.5263157895vw;
  }
}
@media (max-width: 1100px) {
  .index-pagePro-swiper::before {
    width: 100%;
    height: 21rem;
  }
}
@media (max-width: 380px) {
  .index-pagePro-swiper::before {
    width: 100%;
    height: 18.45rem;
  }
}
@media (min-width: 1000px) and (max-width: 1600px) {
  .index-pagePro-swiper-box {
    padding-left: 2.6315789474vw;
    padding-right: 2.6315789474vw;
  }
}
.index-pagePro-swiper .desbox {
  padding-top: 2.3684210526vw;
}
@media (min-width: 1000px) and (max-width: 1600px) {
  .index-pagePro-swiper .desbox {
    padding-left: 2.1052631579vw;
    padding-right: 2.1052631579vw;
  }
}
.index-pagePro-swiper .card {
  background-color: transparent;
}
@media (min-width: 1101px) {
  .index-pagePro-swiper .card {
    margin-bottom: 3.4210526316vw;
    grid-row: span 2;
  }
  .index-pagePro-swiper .card-wrap {
    display: grid;
    grid-template-columns: repeat(2, minmax(270px, 1fr));
    margin-bottom: 0;
  }
  .index-pagePro-swiper .card:nth-child(even) {
    grid-row: span 1;
  }
  .index-pagePro-swiper .card:nth-last-of-type(1), .index-pagePro-swiper .card:nth-last-of-type(2) {
    margin-bottom: 1.5789473684vw;
  }
}
@media (max-width: 1100px) {
  .index-pagePro-swiper .card-wrap {
    margin-top: 0;
  }
}
.index-pagePro-swiper .card-img-top {
  text-align: center;
  margin-bottom: 20px !important;
}
.index-pagePro-swiper .card-img-top img {
  width: 100% !important;
}
@media (max-width: 1100px) {
  .index-pagePro-swiper .card-img-top {
    margin-bottom: 0.5rem !important;
  }
}
.index-pagePro-swiper .card-title {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 12px !important;
}

.titlebox {
  display: inline-block;
  padding-right: 3.6842105263vw;
  padding-top: 3.9473684211vw;
  border-right: #222222 1px solid;
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
.page-banner.index .fancy-slider-title {
  position: absolute;
  bottom: 4.6875vw;
}
@media (min-width: 1101px) {
  .page-banner.index .fancy-slider-title {
    bottom: 6.8421052632vw;
  }
  .page-banner.index .fancy-slider-scale {
    position: relative;
  }
}
@media (max-width: 1100px) {
  .page-banner.index {
    height: 46.875vw;
  }
}
@media (max-width: 640px) {
  .page-banner-main img {
    height: 100px;
    object-fit: cover;
  }
}
.page-scrollDown {
  position: relative;
  height: 75px;
  margin: 2em;
}
.page-scrollDown-btn {
  position: absolute;
  width: 37px;
  height: 72px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  animation: jump 1.5s infinite;
}
@media (max-width: 640px) {
  .page-scrollDown {
    display: none;
  }
}
.page-gallerSwiper {
  overflow: hidden;
}
.page-gallerSwiper .swiper-pagination {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.page-gallerSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 0;
  margin: 0 !important;
}
.page-gallerSwiper .swiper-pagination-bullet-active {
  background-color: #ef8586;
}
.page-gallerSwiper .card-body {
  padding: 0;
}
.page-gallerSwiper .card-title, .page-gallerSwiper .card-time, .page-gallerSwiper .card-img-top {
  margin-bottom: 25px;
}
.page-gallerSwiper .card-title {
  font-weight: 400;
  line-height: 1.15;
  word-wrap: normal;
}
.page-gallerSwiper .card-img-top img {
  display: inline-block;
}
@media (min-width: 760px) {
  .page-gallerSwiper .card-img-top img {
    width: auto;
  }
}
.page-gallerSwiper .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper .swiper-pagination-bullet {
    opacity: 0.056;
  }
  .page-gallerSwiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper .swiper-pagination {
    justify-content: center;
  }
}
.page-gallerSwiper-news {
  padding-bottom: 10px;
}
@media (min-width: 760px) {
  .page-gallerSwiper-news {
    height: 0;
    padding-bottom: 36%;
  }
}
@media (min-width: 765px) and (max-width: 1000px) {
  .page-gallerSwiper-news {
    padding-bottom: 45%;
  }
}
@media (min-width: 760px) {
  .page-gallerSwiper-news .swiper-slide {
    transition: 300ms;
    transform: scale(0.7) !important;
    transform-origin: top;
  }
}
.page-gallerSwiper-news .swiper-slide .card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
@media (min-width: 900px) {
  .page-gallerSwiper-news .swiper-slide .card {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 765px) and (max-width: 1000px) {
  .page-gallerSwiper-news .swiper-slide .card {
    margin-right: 1.5em;
  }
}
.page-gallerSwiper-news .swiper-slide .card-title {
  font-size: 48px;
  font-size: 3rem;
}
@media (min-width: 1100px) and (max-width: 1700px) {
  .page-gallerSwiper-news .swiper-slide .card-title {
    font-size: 38px;
    font-size: 2.375rem;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper-news .swiper-slide .card-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.page-gallerSwiper-news .swiper-slide .card-time {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-news .swiper-slide .card {
    padding-left: 3.9473684211vw;
    padding-right: 3.9473684211vw;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-news .swiper-slide-prev .card {
    padding-left: 0;
    padding-right: 8.4210526316vw;
    margin-left: -2.1052631579vw;
  }
}
@media (min-width: 760px) {
  .page-gallerSwiper-news .swiper-slide-prev .card-img-top img, .page-gallerSwiper-news .swiper-slide-next .card-img-top img {
    width: 143px;
    height: 210px;
    object-fit: cover;
    opacity: 0.45;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-news .swiper-slide-next .card {
    padding-left: 7.3684210526vw;
    padding-right: 0;
  }
  .page-gallerSwiper-news .swiper-slide-next .card-img-top {
    order: -1;
  }
}
.page-gallerSwiper-news .swiper-slide-active .card-title {
  font-size: 40px;
  font-size: 2.5rem;
  -webkit-text-stroke: 2px #000;
}
@media (min-width: 1100px) and (max-width: 1700px) {
  .page-gallerSwiper-news .swiper-slide-active .card-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper-news .swiper-slide-active .card-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.page-gallerSwiper-news .swiper-slide-active .card-img-top {
  width: 21.0526315789vw;
  height: 0;
  margin-bottom: 0;
}
.page-gallerSwiper-news .swiper-slide-active .card-img-top img {
  bottom: auto;
}
@media (min-width: 760px) {
  .page-gallerSwiper-news .swiper-slide-active .card-img-top {
    padding-bottom: 100%;
    margin-left: auto;
  }
}
@media (max-width: 759px) {
  .page-gallerSwiper-news .swiper-slide-active .card-img-top {
    width: auto;
    height: auto;
    margin-top: 10px;
    text-align: center;
  }
  .page-gallerSwiper-news .swiper-slide-active .card-img-top img {
    position: static;
    margin: 0;
  }
}
.page-gallerSwiper-news .swiper-slide-active .card-time {
  font-size: 82.5%;
  margin-bottom: 0.15rem;
}
@media (min-width: 760px) {
  .page-gallerSwiper-news .swiper-slide-active .card-body {
    position: absolute;
    bottom: 10px;
    z-index: 1;
    width: 21.875vw;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-news .swiper-slide-active .card-body {
    width: 14.2105263158vw;
  }
}
.page-gallerSwiper-news .swiper-slide-active .card::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #222222;
  position: absolute;
  left: -3.9473684211vw;
}
.page-gallerSwiper-news .swiper-slide-active .card::after {
  content: "";
  display: block;
  width: 1px;
  height: 10.5263157895vw;
  background-color: #222222;
  position: absolute;
  right: -3.9473684211vw;
  bottom: 0;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-news .swiper-slide-active .card {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 700px) {
  .page-gallerSwiper-news .swiper-slide-active {
    transform: scale(1.1) !important;
  }
}
.page-gallerSwiper-news .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper-news .swiper-pagination {
    width: 20vw !important;
    padding-left: 2.6315789474vw;
    bottom: 0vw !important;
  }
}
.page-gallerSwiper-prod {
  margin-top: 1.0526315789vw;
  padding-bottom: 1.0526315789vw;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-prod {
    margin-left: -1em;
    margin-right: -1em;
  }
}
@media (min-width: 760px) {
  .page-gallerSwiper-prod .swiper-pagination {
    justify-content: flex-end;
    padding-right: 2.6315789474vw;
  }
}

/* swiper */
.fancy-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: var(--fancy-slider-font-family);
  --fancy-slider-title-font-size: 88px;
}
.fancy-slider .swiper {
  width: 100%;
  height: 100%;
  transition-duration: var(--fancy-slider-transition-background-duration);
  transition-property: background-color;
  transition-delay: var(--fancy-slider-transition-background-delay);
}
.fancy-slider .swiper-wrapper {
  transition-delay: var(--fancy-slider-transition-slide-delay);
  transition-timing-function: var(--fancy-slider-transition-timing-function);
}
.fancy-slider .swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fancy-sliderSwiper-sec04 {
  align-items: flex-end !important;
  justify-content: flex-end !important;
}
@media (min-width: 1101px) {
  .fancy-sliderSwiper-sec04 .fancy-slider-title-head {
    top: -5.7894736842vw;
    left: -12.6315789474vw;
  }
}

.fancy-slider-no-transition {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition *:not(.swiper-wrapper) {
  transition-duration: 0ms !important;
  transition-delay: 0ms !important;
  transition-property: none !important;
}
.fancy-slider-no-transition .swiper-wrapper {
  transition-delay: 0ms !important;
}

.fancy-slider-title {
  transition-delay: var(--fancy-slider-transition-title-delay);
  z-index: 10;
}
.fancy-slider-title-head {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2.1052631579vw;
  margin-bottom: 1.0526315789vw;
  transition-delay: var(--fancy-slider-transition-title-delay);
}
@media (min-width: 1101px) {
  .fancy-slider-title-head {
    left: 10vw;
  }
}
.fancy-slider-title-text {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 5.46875vw;
  line-height: var(--fancy-slider-title-line-height);
  font-family: var(--fancy-slider-title-font-family);
  letter-spacing: -1px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
@media (min-width: 1101px) {
  .fancy-slider-title-text {
    font-size: 3.9473684211vw;
  }
}
@media (max-width: 1100px) {
  .fancy-slider-title-text {
    color: rgb(255, 255, 255) !important;
  }
}
.fancy-slider-title-subtext {
  font-family: "Poppins", sans-serif;
  letter-spacing: 3px;
  font-size: 2.5vw;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 2px #fff;
}
@media (min-width: 1101px) {
  .fancy-slider-title-subtext {
    font-size: 1.1578947368vw;
  }
}

.fancy-slider-scale {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(var(--fancy-slider-transform-scale-slide));
  transition-property: transform;
  transition-timing-function: var(--fancy-slider-transition-timing-function);
  opacity: 0.9;
  overflow: hidden;
}
.fancy-slider-scale img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(var(--fancy-slider-transform-scale-img));
  transition-timing-function: var(--fancy-slider-transition-timing-function);
  transition-property: transform;
}

.fancy-slider-button {
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  width: 140px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (max-width: 1100px) {
  .fancy-slider-button {
    width: 100px;
  }
}
.fancy-slider-button svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.fancy-slider-button .fancy-slider-svg-circle-wrap {
  transition: var(--fancy-slider-transition-button-circle);
  transform-origin: -20px 40px;
  opacity: 1;
}
.fancy-slider-button circle {
  transition: var(--fancy-slider-transition-button-circle);
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 1;
  transform-origin: 0px 0px 0px;
}
.fancy-slider-button .fancy-slider-svg-arrow {
  transition: var(--fancy-slider-transition-button-arrow);
  fill: #fff;
  transform: rotateY(180deg) translate(-49px, 31.1px) scale(3);
}
.fancy-slider-button .fancy-slider-svg-wrap {
  transform: translateY(353px);
}
.fancy-slider-button .fancy-slider-svg-line {
  transition: var(--fancy-slider-transition-button);
  stroke: #fff;
  stroke-width: 2;
  transform: translate(50px, 42px);
}
.fancy-slider-button:not(.fancy-slider-button-disabled):hover circle {
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
  opacity: 1;
}

.fancy-slider-button-prev {
  left: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-prev:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(-16px);
}

.fancy-slider-button-next {
  right: var(--fancy-slider-button-side-offset);
}
.fancy-slider-button-next .fancy-slider-svg-wrap {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.fancy-slider-button-next:not(.fancy-slider-button-disabled):hover svg {
  transform: translate(16px);
}

.fancy-slider-button-disabled {
  opacity: 0.2;
  cursor: default;
}

.fancy-slider .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.fancy-slider .swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.fancy-slider .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  transform: translate(0);
}
.fancy-slider .swiper-android .swiper-slide {
  transform: translate(0);
}
.fancy-slider .swiper-pointer-events {
  touch-action: pan-y;
}
.fancy-slider .swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}
.fancy-slider .swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.fancy-slider .swiper-slide-invisible-blank {
  visibility: hidden;
}
.fancy-slider .swiper-autoheight {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-slide {
  height: auto;
}
.fancy-slider .swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.fancy-slider .swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.fancy-slider .swiper-3d {
  perspective: 1200px;
}
.fancy-slider .swiper-3d .swiper-wrapper {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d .swiper-slide-shadow {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background: rgba(0, 0, 0, 0.15);
}
.fancy-slider .swiper-3d .swiper-slide-shadow-left {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-right {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-top {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-slide-shadow-bottom {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.fancy-slider .swiper-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.fancy-slider .swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.fancy-slider .swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}
.fancy-slider .swiper-horizontal.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}
.fancy-slider .swiper-vertical.swiper-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}
.fancy-slider .swiper-centered > .swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}
.fancy-slider .swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-horizontal > .swiper-wrapper:before {
  height: 100%;
  width: var(--swiper-centered-offset-after);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}
.fancy-slider .swiper-centered.swiper-vertical > .swiper-wrapper:before {
  width: 100%;
  height: var(--swiper-centered-offset-after);
}
@media (max-width: 480px) {
  .fancy-slider .fancy-slider {
    --fancy-slider-title-font-size: 60px;
  }
}
@media (max-width: 640px) {
  .fancy-slider .fancy-slider-button-prev {
    left: var(--fancy-slider-button-mobile-side-offset);
  }
  .fancy-slider .fancy-slider-button-next {
    right: var(--fancy-slider-button-mobile-side-offset);
  }
}

.about-page-mainWrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.about-page-mainWrapper::before {
  max-height: 45.7894736842vw;
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .about-page-mainWrapper::before {
    max-height: 56.3157894737vw;
  }
}
@media (max-width: 1100px) {
  .about-page-mainWrapper::before {
    max-height: 140.625vw;
  }
}
@media (max-width: 640px) {
  .about-page-mainWrapper::before {
    max-height: 70.3125vw;
  }
}
@media (min-width: 1101px) {
  .about-content {
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
.about-intro {
  align-items: center;
}
@media (min-width: 1101px) {
  .about-intro {
    margin-bottom: 7.3684210526vw;
  }
}
@media (max-width: 1100px) {
  .about-intro {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1101px) {
  .about-intro-img {
    flex: 0 0 51.0526315789vw;
    margin-right: -13%;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .about-intro-img {
    margin-right: -1.0526315789vw;
  }
}
.about-intro-txt-subHead {
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}
.about-intro-txt-head {
  font-size: 36px;
  font-size: 2.25rem;
}
.about-intro-txt-des {
  padding-top: 2.3684210526vw;
  padding-bottom: 3.6842105263vw;
  line-height: 2;
}
@media (min-width: 1101px) {
  .about-intro-txt {
    padding-right: 3.4210526316vw;
  }
}
.about-card-wrap .card {
  background-color: transparent;
}
@media (min-width: 1101px) {
  .about-card-wrap .card-img-top {
    flex: 0 0 34.3333333333%;
    max-width: 34.3333333333%;
  }
}
.about-card-wrap .card-img-top img {
  width: auto;
}
@media (min-width: 1101px) {
  .about-card-wrap .card-body {
    flex: 0 0 65.6666666667%;
    max-width: 65.6666666667%;
    padding-left: 8.4210526316vw;
  }
}
.about-card-wrap .card-title {
  position: relative;
  font-size: 25px;
  font-size: 1.5625rem;
}
.about-card-wrap .card-title::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #c49d36;
  border-radius: 50%;
  position: absolute;
  top: 0rem;
  left: -1rem;
}
@media (min-width: 1101px) {
  .about-card-wrap .card-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .about-card-wrap .card-title::before {
    left: -2rem;
  }
}
.about-card-wrap .card-des {
  line-height: 2;
  padding-top: 5px;
}
@media (min-width: 1101px) {
  .about-card-wrap .card-body {
    display: inline-flex;
  }
  .about-card-wrap .card-body .card-title {
    flex: none;
    padding-right: 3.6842105263vw;
  }
}
.about-card-wrap .card-img-big {
  flex-grow: 1;
  max-width: 100%;
}
.about-card-wrap .card-body-big {
  flex: 1.35;
}
@media (min-width: 1101px) {
  .about-card-wrap .card-body-big {
    padding-left: 2rem;
  }
}
@media (max-width: 1100px) {
  .about-card-wrap .card-body-big {
    padding-bottom: 0;
  }
}
@media (min-width: 1101px) {
  .about-card-wrap .card-reverse .card-img-top {
    order: 1;
    flex: 0 0 38.6666666667%;
    max-width: 38.6666666667%;
  }
  .about-card-wrap .card-reverse .card-body {
    flex: 0 0 61.3333333333%;
    max-width: 61.3333333333%;
    padding-left: 3.1578947368vw;
    padding-right: 3.1578947368vw;
  }
}
@media (min-width: 1101px) {
  .about-card-wrap .card {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .about-card-wrap .card-sec-01 {
    margin-top: -6.3157894737vw;
    margin-bottom: 3.6842105263vw;
  }
  .about-card-wrap .card-sec-02 {
    margin-top: -2.6315789474vw;
  }
  .about-card-wrap .card-sec-02 .card-img-top {
    margin-top: -5.2631578947vw;
  }
  .about-card-wrap .card-sec-02 .card-des {
    padding-right: 1rem;
  }
  .about-card-wrap .card-sec-03 {
    margin-top: 5.2631578947vw;
  }
}
@media (max-width: 1100px) {
  .about-card-wrap .card {
    flex: 0 0 calc(100% - 1em);
    max-width: calc(100% - 1em);
  }
  .about-card-wrap .card:last-child {
    margin-bottom: 0;
  }
}

.news-card-img-top {
  border: #d9d9d9 2px solid;
  margin-bottom: -2px !important;
}
.news-card-img-top img {
  width: 100%;
}
.news-card-title {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "century-gothic", "Noto Sans TC";
  line-height: 1.25;
  margin-bottom: 1rem;
  font-weight: 600;
}
@media (min-width: 1101px) {
  .news-card-title {
    font-size: 30px;
    font-size: 1.875rem;
    min-height: 4.75rem;
  }
}
.news-card-des {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif, "Noto Sans TC";
  color: #5b5a5a;
}
@media (min-width: 1101px) {
  .news-card-des {
    padding-top: 1.5789473684vw;
    padding-bottom: 3.9473684211vw;
    line-height: 1.5;
  }
}
.news-card-time {
  font-family: "Roboto";
  margin-top: auto;
  margin-bottom: 0.25em;
  padding-top: 1em;
}
.news-card-wrap .card-body, .news-card-wrap .card-btn {
  width: 100%;
  border: #d9d9d9 2px solid;
  padding: 1.25em 1.5em;
}
@media (max-width: 1100px) {
  .news-card-wrap .card-body, .news-card-wrap .card-btn {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}
.news-card-wrap .card-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1101px) {
  .news-card-wrap .card-body {
    padding-top: 1.5em;
    padding-bottom: 0.5em;
  }
}
@media (max-width: 1100px) {
  .news-card-wrap .card-body {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
  }
}
.news-card-wrap .card-body .card-time {
  margin-top: auto;
}
.news-card-wrap .card-btn {
  background-color: transparent !important;
  color: #000 !important;
  justify-content: flex-start;
  margin-top: -2px;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  .news-card-wrap .card-btn {
    padding-top: 0.5em;
    padding-bottom: 0.7em;
  }
}
@media (min-width: 1101px) {
  .news-card-wrap .card {
    margin-left: 2.1052631579vw;
    margin-right: 2.1052631579vw;
    flex: 0 0 calc(33.3333333333% - 4.2105263158vw);
    max-width: calc(33.3333333333% - 4.2105263158vw);
  }
}
.news-content .news-card-time {
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}
.news-content-head {
  margin-bottom: 1rem;
}
@media (min-width: 1101px) {
  .news-content-head {
    float: left;
    width: 33.3333333333%;
    min-height: 30vw;
    padding-right: 6.3157894737vw;
  }
}
@media (min-width: 1101px) and (min-width: 1101px) {
  .news-content-head .news-card-title {
    margin-bottom: 2.6315789474vw;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .news-content-head {
    min-height: 36.8421052632vw;
  }
}
.news-content-article {
  font-family: "Poppins", sans-serif, "Noto Sans TC";
  line-height: 1.75;
}

.pro-in-content {
  background-color: #f1f0f0;
  padding: 1rem;
}
@media (min-width: 1101px) {
  .pro-in-content {
    padding-top: 3.1578947368vw;
    padding-bottom: 3.1578947368vw;
    margin-left: -1em;
    margin-right: -1em;
  }
}
@media (max-width: 380px) {
  .pro-in-content {
    padding: 0.5rem;
  }
}

.pro-card-title {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "century-gothic";
  line-height: 1.25;
  margin-bottom: 1rem;
  font-weight: 600;
}
@media (min-width: 1101px) {
  .pro-card-title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.pro-card-img-top {
  overflow: hidden;
}
.pro-card-img-top img {
  transition: 0.25s;
}
@media (min-width: 1500px) {
  .pro-card-wrap {
    margin-left: -1em;
    margin-right: -1em;
  }
}
.pro-card-wrap .card:hover .pro-card-img-top img {
  transform: scale(1.25);
}
.pro-linkWrap {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  justify-content: center;
}
.pro-linkWrap a {
  font-family: "Open Sans", "Noto Sans TC";
  font-size: 18px;
  font-size: 1.125rem;
  color: #606060;
  padding: 20px 25px;
  display: inline-block;
}
@media (max-width: 810px) {
  .pro-linkWrap a {
    font-size: 16px;
    font-size: 1rem;
    padding: 1rem 0.25rem;
  }
}
@media (max-width: 380px) {
  .pro-linkWrap a {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.pro-linkWrap li, .pro-linkWrap a {
  text-align: center;
}
.pro-linkWrap li {
  border: transparent 2px solid;
  transition: 0.25s;
}
.pro-linkWrap li:hover, .pro-linkWrap li.active {
  border-color: #818181;
}
.pro-linkWrap li:hover a, .pro-linkWrap li.active a {
  color: #000;
}
@media (min-width: 1101px) {
  .pro-linkWrap li {
    flex-basis: 270px;
    margin: 0 10px;
  }
}
@media (max-width: 810px) {
  .pro-linkWrap li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1101px) {
  .pro-linkWrap {
    margin: 2rem 0 3rem;
  }
}

.pro-heading-des {
  border-bottom: 1px solid #676767;
  margin-top: 20px;
  margin-bottom: 45px;
}
.pro-heading-img {
  margin-bottom: 20px;
}
.pro-heading-txt {
  color: #464545;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 10px 15px;
}

.Prod-article {
  padding: 1rem;
}
@media (min-width: 1101px) {
  .Prod-article {
    padding-top: 2.1052631579vw;
    padding-bottom: 2.1052631579vw;
  }
}
.Prod-article-title {
  font-family: "Poppins", sans-serif;
}
.Prod-article-content {
  line-height: 1.75;
  margin-top: 4rem;
}
.Prod-conInfo-img {
  overflow: hidden;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img {
    flex-basis: 660px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img {
    padding-top: 0.5em;
  }
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  background-color: #fff;
  overflow: hidden;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  margin: auto;
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  color: #000;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (max-width: 810px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -6.5em;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -4em;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img .swiper-button-next {
    right: 0;
  }
  .Prod-conInfo-img .swiper-button-prev {
    left: 0;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  width: 100%;
  height: 80%;
  margin-bottom: 0.25em;
}
.Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
  padding-bottom: 73%;
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
    border: #eee 1px solid;
    padding-bottom: 75%;
  }
}
@media (min-width: 1101px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    margin-bottom: 0;
    height: 100%;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  opacity: 0.4;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    border: #eee 1px solid;
    padding-bottom: 23%;
  }
}
@media (min-width: 1101px) {
  .Prod-conInfo-img .Prod_mySwiper {
    padding: 0;
    height: 100%;
  }
}
.Prod-conInfo-info {
  padding-top: 0.5em;
}
@media (min-width: 1101px) {
  .Prod-conInfo-info {
    flex: 1;
    padding-left: 3.5em;
  }
}
@media (min-width: 1101px) {
  .Prod-conInfo {
    display: flex;
    margin-top: 1.75em;
    margin-bottom: 2.5em;
  }
}
.Prod-heading {
  line-height: 1.5;
  padding-bottom: 1.75em;
}
.Prod-headingTxt {
  font-size: 16px;
  font-size: 1rem;
  color: #585858;
  padding-bottom: 10px;
}
@media (min-width: 1101px) {
  .Prod-headingTxt {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.Prod-headingTitle {
  font-family: "century-gothic";
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: 0.75rem;
}
.Prod-headingSubTitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
}
.Prod-conTxt {
  border-top: 3px solid #292929;
  border-bottom: 1px solid #292929;
  padding: 1.7em 0 1em 1em;
  margin-bottom: 1.5em;
}
.Prod-conTxt dl {
  display: flex;
  align-items: center;
}
.Prod-conTxt dl dt {
  flex-basis: 100px;
}
.Prod-conTxt dl dd {
  padding-left: 12px;
  margin-bottom: 0;
  font-size: 20px;
  font-size: 1.25rem;
  color: #565555;
}
@media (min-width: 1101px) {
  .Prod-photo {
    width: 80%;
  }
  .Prod-photo-small {
    width: 20%;
    height: 100%;
    max-height: 21.5em;
    order: -1;
    overflow: hidden;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.Prod-btn li {
  text-align: center;
}
.Prod-btn .btn {
  color: #fff !important;
  border-radius: 0;
}
@media (min-width: 1101px) {
  .Prod-btn {
    margin-top: 3rem;
  }
  .Prod-btn li {
    flex: none;
  }
  .Prod-btn .btn {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (max-width: 1100px) {
  .Prod-btn .btn {
    width: 100%;
  }
}
.Prod-unreset {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Prod-unreset .nav-tabs {
  border-bottom: none;
}
.Prod-unreset .nav-item {
  min-height: auto;
}
@media (min-width: 1101px) {
  .Prod-unreset .nav-item {
    flex-basis: 270px;
    margin-right: 10px;
  }
}
.Prod-unreset .nav-link {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 0;
  color: #6b6b6b;
  background-color: #f5f3f3;
  padding: 1em 2em !important;
}
.Prod-unreset .nav-link:hover, .Prod-unreset .nav-link:focus, .Prod-unreset .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #b3b3b3;
  border-width: 1px 1px 0 1px;
  box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.07);
}
.Prod-unreset .nav-link:hover::before, .Prod-unreset .nav-link:focus::before, .Prod-unreset .nav-link.active::before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: -0.1em;
  z-index: 2;
}
@media (min-width: 1101px) {
  .Prod-unreset .nav-link {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.Prod-unreset .tab-content {
  border: #b3b3b3 1px solid;
  padding: 1em;
}
@media (min-width: 1101px) {
  .Prod-unreset .tab-content {
    padding: 1.5em 2em;
  }
}
@media (min-width: 1101px) {
  .Prod-unreset {
    padding-left: 1em;
  }
}
.Prod-table {
  max-width: 60em;
  margin: 1em auto;
  padding: 10px;
  border-radius: 10px;
  border: #6b6b6b 2px solid;
  overflow: hidden;
}
.Prod-table table {
  border: none;
  margin-bottom: 0;
}
.Prod-table table th,
.Prod-table table td {
  border: none;
  padding-left: 1.5em;
}
.Prod-table table td {
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
}
.Prod-table table td .box {
  line-height: 100%;
  background-color: #e9edf4;
  border-top: 1px solid #d4dbe8;
  border-bottom: 1px solid #d4dbe8;
  padding-left: 1.85rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.Prod-table table td .box-name {
  color: #1a1d89;
  font-size: 1.15em;
}
@media (min-width: 1101px) {
  .Prod-table table td:not(:last-child) .box {
    border-right: #bac5da 1px solid;
  }
}
.Prod-table table tr:nth-child(even) td .box {
  background-color: #eeeeee;
}
@media (min-width: 1101px) {
  .Prod-table table tr:not(:last-child) .box {
    margin-bottom: 5px;
  }
}
@media (max-width: 1100px) {
  .Prod-table table,
.Prod-table table tbody,
.Prod-table table tr,
.Prod-table table td {
    display: block;
    width: 100%;
  }
  .Prod-table table thead,
.Prod-table table th {
    display: none;
  }
  .Prod-table table td {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .Prod-table table td::before {
    content: attr(data-title);
    flex-basis: 100px;
  }
  .Prod-table table td .box {
    flex: 1;
  }
}
@media (min-width: 1101px) {
  .Prod-table {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

.pro-wrap {
  overflow: hidden;
  padding: 1em 0;
}
.pro-article {
  background-color: #f1f0f0;
  padding: 0.5em 1em;
  margin-bottom: 2em;
}
@media (min-width: 1101px) {
  .pro-article {
    font-size: 20px;
    font-size: 1.25rem;
    padding: 50px;
  }
}
.pro-content {
  padding-top: 10px;
}
.pro-search {
  margin-top: 20px;
}
.pro-searchForm {
  margin-top: 25px;
}
.pro-searchForm .form-control,
.pro-searchForm .btn {
  border-radius: 0;
}
.pro-searchForm .form-control {
  height: 42px;
  appearance: button;
}
.pro-searchList {
  flex: auto;
}
.pro-searchList-input li {
  display: inline-flex;
}
.pro-searchList-input li:first-child input.form-control {
  flex: 1;
}
@media (min-width: 1101px) {
  .pro-searchList-input li:first-child {
    flex: none;
    width: 330px;
  }
}
.pro-searchList-btn {
  flex: none !important;
}
@media (min-width: 1101px) {
  .pro-searchList li {
    flex-basis: 250px;
    padding-right: 10px;
  }
}
@media (min-width: 1100px) and (max-width: 1700px) {
  .pro-searchList li {
    flex: 1;
  }
}
@media (max-width: 1100px) {
  .pro-searchList li {
    flex: 1 0 calc(50% - 10px);
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .pro-searchList-input li {
    flex: 1;
  }
  .pro-searchList-input li + li {
    flex: none;
  }
}
@media (max-width: 1100px) {
  .pro-search label {
    margin-bottom: 5px;
  }
}