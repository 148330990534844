//@extend class
* {
    box-sizing: border-box;
    // border: #000 1px solid;
}

html {
    overflow: -moz-scrollbars-vertical;
    @extend %pm0;
}

body {
    background: $colorBack;
    visibility: visible;
    @extend %baseFont;
    @extend %pm0;
    overflow-x: hidden;
    &.fixed {
        overflow: hidden;
    }
}

div {
    position: relative;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

// 表單相關
form {
    font-size: $fzBase;
    @extend %pm0;
}

label {
    display: inline-flex;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
}

input {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
    }
    -webkit-autofill:unset;
    font-size: $fzBase;
    @extend %pm0;
    background-color: #eeeeee;
    &:focus {
        outline: 0;
    }
    &[type='text'],
    &[type='password'] {
        vertical-align: top;
    }
    &[type="radio"],
    &[type="checkbox"] {
        margin-right: 5px;
        cursor: pointer;
        vertical-align: middle;
    }
    &[type=number]::-webkit-inner-spin-button,
    [type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
        -moz-appearance: textfield;
    }
    &.hid {
        display: none;
    }
}

a,
input,
select,
button {
    &:focus {
        box-shadow: none !important;
        outline: none;
    }
}

select {
    border: 0px;
    outline: none;
}

textarea {
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, .8);
    box-sizing: border-box;
    resize: none;
    font-size: $fzBase;
    background-color: #eeeeee;
}

a {
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
    @extend %baseFont;
    &:hover,
    &:focus {
        text-decoration: none;
        color: currentColor;
    }
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6{
    margin: 0;
}

.clear {
    clear: both;
    height: 0px;
    line-height: 0px;
    font-size: 0px;
    overflow: hidden;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hide {
    display: none !important;
    @include setMaxRwd {
        display: block !important;
    }
}

.icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
}

// 最大寬度
.mw-auto{
    max-width: none !important
}

.wrapper-1800 {
    @include maxWidth(1800px);
}

.wrapper-1750 {
    @include maxWidth(1750px);
}

.wrapper-1700 {
    @include maxWidth(1700px);
}

.wrapper-1650 {
    @include maxWidth(1650px);
}

.wrapper {
    @include maxWidth(1600px);
}

.wrapper-1550 {
    @include maxWidth(1550px);
}

.wrapper-1500 {
    @include maxWidth(1500px);
}

.wrapper-1450 {
    @include maxWidth(1450px);
}

.wrapper-1400 {
    @include maxWidth(1400px);
}

.wrapper-1350 {
    @include maxWidth(1350px);
}

.wrapper-1300 {
    @include maxWidth(1300px);
}

.wrapper-1250 {
    @include maxWidth(1250px);
}

.wrapper-1200 {
    @include maxWidth(1200px);
}

.wrapper-1150 {
    @include maxWidth(1150px);
}

.wrapper-1100 {
    @include maxWidth(1100px);
}

.wrapper-1050 {
    @include maxWidth(1050px);
}

.wrapper-1000 {
    @include maxWidth(1000px);
}

.wrapper-900 {
    @include maxWidth(900px);
}

.wrapper-800 {
    @include maxWidth(800px);
}

.wrapper-750 {
    @include maxWidth(750px);
}

.wrapper-700 {
    @include maxWidth(700px);
}

.wrapper-650 {
    @include maxWidth(650px);
}
// End
