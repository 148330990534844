// 按鈕Hover的狀態
%btnMainHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}

%btnMainHover,
%btnoutlineHover{
    background-color: $colorMain !important;
    border-color: $colorMain !important;
    color: #fff !important;
}
%btnItemHover{
    background-color: $colorItem !important;
    border-color: $colorItem !important;
    color: #fff !important;
}

// 按鈕樣式
.btn{
    @include fontSize(18px);
    font-weight: 100;
    border-radius: 0 !important;
    padding: 0.35em 1.5em 0.5em;

    &:hover{
        @extend %btnMainHover;
    }

    @include setMaxRwd{
        @include fontSize(16px);
        padding-left: 1em;
        padding-right: 1em;
    }

    .fa{
        font-size: 0.6em;
        font-weight: 100;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 6px;
    }

    .icon {
        vertical-align: top;
        margin-top: 0px;
        margin-right: 3px;
    }

    // 從無框有背景延伸
    &-main{
        border:1px solid $colorMain !important;
        background-color: $colorMain !important;
        color:#fff !important;
        &:hover{
            @extend %btnItemHover;
        }
    }

    &-item{
        border:1px solid $colorItem;
        background-color: $colorItem;
        color:#fff;
        &:hover{
            @extend %btnMainHover;
        }
    }

    &-medium{
        padding: 0.75rem 2em !important
    }

    &-big{
        padding: 1.15rem 3em !important;
    }

    &-small{
        padding: 0.25rem 0.5rem !important;
        font-size: 16px !important;
    }

    &-tag{
        padding: 6px 12px !important;
        @include fontSize(14px);
        line-height: 1.25;
        @extend .tag-item
    }

    &-noRadius{
        border-radius: 0;
    }

    // 包按鈕的框
    &-wrap {
        @extend %setFlex-center;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 1rem;
        &>li {
            flex: 1;
            &+li {
                margin-left: 1.5rem;
            }
        }
        @include pad1080 {
            margin-top: 1rem;
            margin-bottom: 2rem;
            &>li {
                &+li {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    // 顏色
    &-skin{
        background-color: #dec9bc;
    }

    &-white{
        background-color: #fff;
    }
}

.js-toggle-btn{
    .btn{
        @include fontSize(16px);
        @include radius(20px);
        border: #eee 1px solid;
        i{
            color: currentColor;
        }

        .icon{
            margin-top: 0;
        }
    }
}

.js-open{
    display: block !important;
    margin-top: 5px;
}