.pro-in-content{
    background-color: #f1f0f0;
    padding: 1rem;
    @include setMinRwd{
        padding-top: get-vw(60px);
        padding-bottom: get-vw(60px);
        margin-left: -1em;
        margin-right: -1em;
    }

    @include mobileSmall{
        padding: 0.5rem; 
    }
}

.pro{
    // 列表
    &-card{
        &-title{
            @include fontSize(20px);
            font-family: "century-gothic";
            line-height: 1.25;
            margin-bottom: 1rem;
            font-weight: 600;
            @include setMinRwd{
                @include fontSize(24px);
            }
        }

        &-img-top{
            overflow: hidden;
            img{ 
                transition: 0.25s;
            }
        }

        &-wrap{
            @include setStyleMinRwd(1500px){
                margin-left: -1em;
                margin-right: -1em;
            }

            .card{
                &:hover{
                    .pro-card-img-top{
                        img{
                            transform: scale(1.25);
                        }
                    }
                }
            }
        }
    }

    // 列表連結
    &-linkWrap{
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        a{
            font-family: $OpenSans-300, $fontNotosanc-tc;
            @include fontSize(18px);
            color: #606060;
            padding: 20px 25px;
            display: inline-block;
            @include pad{
                @include fontSize(16px);
                padding: 1rem 0.25rem;
            }

            @include mobileSmall{
                @include fontSize(15px);
            }
        }

        @extend %setFlex-center;
        justify-content: center;

        li,a{
            text-align: center;
        }
        
        li{
            border: transparent 2px solid;
            transition: 0.25s;
            &:hover,
            &.active{
                border-color: #818181;
                a{
                    color: #000;
                }
            }

            @include setMinRwd{
                flex-basis: 270px;
                margin: 0 10px;
            }

            @include pad{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        @include setMinRwd{
            margin: 2rem 0 3rem;
        }
    }
}

// 產品項目說明
.pro-heading{
    &-des{
        border-bottom: 1px solid #676767;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    &-img{
        margin-bottom: 20px;
    }

    &-txt{
        color:#464545;
        @include fontSize(18px);
        padding: 10px 10px 15px;
    }
}

// 產品詳細頁
.Prod{
    &-article{
        padding: 1rem;
        @include setMinRwd{
            padding-top: get-vw(40px);
            padding-bottom: get-vw(40px);
        }

        &-title{
            font-family: $Poppins;
        }

        &-content{
            line-height: 1.75;
            margin-top: 4rem;
        }
    }

    &-conInfo{
        &-img{
            overflow: hidden;
            @include setMinRwd{
                flex-basis: 660px;
                display: flex;
                flex-flow: row nowrap;
                align-items: flex-start;
            }

            @include setMaxRwd{
                padding-top: 0.5em;
            }

            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    background-color: #fff;
                    overflow: hidden;
                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        max-height: 100%;
                        margin: auto;
                    }
                }

                &-button-next,
                &-button-prev {
                    color: #000;
                    // top: auto;
                    // bottom: get-vw(-95px);
                    &::after{
                        @include fontSize(20px);
                        @extend .fw-900;
                    }
                    
                    @include pad{
                        bottom: -6.5em;
                    }
                    
                    @include mobile{
                        bottom: -4em;
                    }
                }

                @include setMaxRwd{
                    &-button-next{
                        right: 0;
                    }

                    &-button-prev{
                        left: 0;
                    }
                }
            }
            
            // 大圖
            .Prod_mySwiper2 {
                width: 100%;
                height: 80%;
                margin-bottom: 0.25em;
                .swiper {
                    &-slide {
                        padding-bottom: 73%;

                        @include mobile{
                            border: #eee 1px solid;
                            padding-bottom: 75%;
                        }
                    }
                }
                @include setMinRwd{
                    margin-bottom: 0;
                    height: 100%;
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                .swiper{
                    &-slide {
                        opacity: 0.4;
                        &-thumb-active {
                            opacity: 1;
                        }

                        @include mobile{
                            border: #eee 1px solid;
                            padding-bottom: 23%;
                        }
                    }
                }

                @include setMinRwd{
                    padding: 0;
                    height: 100%;
                }
            }
        }

        &-info{
            padding-top: 0.5em;
            @include setMinRwd{
                flex: 1;
                padding-left: 3.5em;
            }
        }

        @include setMinRwd{
            display: flex;
            margin-top: 1.75em;
            margin-bottom: 2.5em;
        }
    }

    &-heading{
        line-height: 1.5;
        padding-bottom: 1.75em;

        &Txt{
            @include fontSize(16px);
            color: #585858;
            padding-bottom: 10px;
            @include setMinRwd{
                @include fontSize(18px);
            }
        }

        &Title{
            font-family: "century-gothic";
            @include fontSize(24px);
            @extend .fw-900;
            margin-top: 0.75rem;
        }
        
        &SubTitle{
            font-family: $Poppins;
            @include fontSize(18px);
        }
    }

    &-conTxt{
        border-top: 3px solid #292929;
        border-bottom: 1px solid #292929;
        padding: 1.7em 0 1em 1em;
        margin-bottom: 1.5em;
        dl{
            display: flex;
            align-items: center;

            dt{
                flex-basis: 100px;
            }

            dd{
                padding-left: 12px;
                margin-bottom: 0;
                @include fontSize(20px);
                color: #565555;
            }
        }
    }

    &-photo{
        @include setMinRwd{
            width: 80%;
            &-small{
                width: 20%;
                height: 100%;
                max-height: 21.5em;
                order: -1;
                overflow: hidden;
                padding-left: 0.5em;
                padding-right: 0.5em;
            }
        }
    }

    &-btn{
        li{
            text-align: center;
        }
        .btn{
            color: #fff !important;
            @include radius(0);
        }
        
        @include setMinRwd{
            margin-top: 3rem;
            li{
                flex: none;
            }
            .btn{
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }

        @include setMaxRwd{
            .btn{
                width: 100%;
            }
        }
    }

    &-unreset{
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        .nav-tabs{
            border-bottom: none;
        }

        .nav-item {
            min-height: auto;

            @include setMinRwd{
                flex-basis: 270px;
                margin-right: 10px;
            }
        }

        .nav-link{
            position: relative;
            width: 100%;
            @include fontSize(16px);
            @include radius(0);
            color: #6b6b6b;
            background-color: #f5f3f3;
            padding: 1em 2em !important;
            &:hover,
            &:focus,
            &.active{
                color: #000;
                background-color: #fff;
                border-color: #b3b3b3;
                border-width: 1px 1px 0 1px;
                box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.07);
                &::before{
                    content: "";
                    display: block;
                    @include setSize(100%,3px);
                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: -0.1em;
                    z-index: 2;
                }
            }

            @include setMinRwd{
                @include fontSize(20px);
            }
        }

        .tab-content{
            border: #b3b3b3 1px solid;
            padding: 1em;
            @include setMinRwd{
                padding: 1.5em 2em;
            }
        }

        @include setMinRwd{
            padding-left: 1em;
        }
    }

    &-table{
        max-width: 60em;
        margin: 1em auto;
        padding: 10px;
        @include radius(10px);
        border: #6b6b6b 2px solid;
        overflow: hidden;
        @extend .boxShadow;
        table{
            border: none;
            margin-bottom: 0;
            th,
            td{
                border: none;
                padding-left: 1.5em;
            }
            
            td{
                padding: 0;
                @include fontSize(16px);
                .box{
                    line-height: 100%;
                    background-color: #e9edf4;
                    border-top:1px solid #d4dbe8;
                    border-bottom:1px solid #d4dbe8;
                    padding-left: 1.85rem;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    &-name{
                        color: #1a1d89;
                        font-size: 1.15em;
                    }
                }
                
                @include setMinRwd{
                    &:not(:last-child){
                        .box{
                            border-right: #bac5da 1px solid;
                        }
                    }
                }
            }

            tr{
                &:nth-child(even){
                    td{
                        .box{
                            background-color: #eeeeee;
                        }
                    }
                }

                @include setMinRwd{
                    &:not(:last-child){
                        .box{
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            @include setMaxRwd{
                &,
                tbody,
                tr,
                td{
                    display: block;
                    width: 100%;
                }

                thead,
                th{
                    display: none;
                }

                td{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    &::before{
                        content: attr(data-title);
                        flex-basis: 100px;
                    }
                    .box{
                        flex: 1;
                    }
                }
            }
        }

        @include setMinRwd{
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }
}

// 產品框
.pro{
    &-wrap{
        overflow: hidden;
        padding: 1em 0;
    }

    &-article{
        background-color: #f1f0f0;
        padding: 0.5em 1em;
        margin-bottom: 2em;

        @include setMinRwd{
            @include fontSize(20px);
            padding: 50px;
        }
    }

    &-content{
        padding-top: 10px;
    }

    &-search{
        margin-top: 20px;

        &Form,
        &List,
        &List li{
            @extend %setFlex-center;
        }

        &Form{
            margin-top: 25px;
            .form-control,
            .btn{
                border-radius: 0;
            }

            .form-control{
                height: 42px;
                appearance: button;
            }
        }

        &List{
            flex: auto;

            &-select{

            }

            &-input{
                li{
                    display: inline-flex;
                    &:first-child{
                        input.form-control{
                            flex: 1;
                        }
                    }

                    @include setMinRwd{
                        &:first-child{
                            flex: none;
                            width: 330px;
                        }
                    }
                }
            }

            &-btn{
                flex: none !important;
            }

            @include setMinRwd{
                li{
                    flex-basis: 250px;
                    padding-right: 10px;
                }
            }

            @include setWidthRwd(1100px,1700px){
                li{
                    flex: 1;
                }
            }

            @include setMaxRwd{
                li{
                    flex: 1 0 calc(50% - 10px);
                    margin-bottom: 10px;
                    margin-right: 10px;
                }

                &-input{
                    li{
                        flex: 1;
                        & + li{
                            flex: none;
                        }
                    }
                }
            }
        }

        @include setMaxRwd{
            label{
                margin-bottom: 5px;
            }
        }
    }
}