// ----- 文字區塊 or 編輯器 ----- //
.article,
article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}

.in-content{
    @include setStyleMinRwd(1600px){
        padding-top: get-vw(50px);
        padding-bottom: get-vw(50px);
    }
}

.imgCenter-4-3{
    position: relative;
    @include setSize(100%,0);
    padding-bottom: 59.5%;
    overflow: hidden;
    img{
        @extend %imgCenter;
        max-height: none;
    }
}

// Google 驗證
.g-recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
    iframe{
        height: auto !important;
    }
}

// 文字粗體
.fw-900 {
    font-weight: 900 !important;
}

// 邊框陰影
.boxShadow{
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

// 邊框
.bt-solid-c1{
    border-top: 1px #ccc solid;
}

// 有左/右內容區塊使用
.mainContent{
    overflow: hidden;
    &-wrap{
        padding-bottom: 2em;
        background-color: #f8f8f8;
        @include setMaxRwd{
            padding-top: 1em;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @extend %setFlex;

    &-info{
        @include setMinRwd{
            width: 440px;

            &~.mainContent-list{
                max-width: calc(100% - 440px);
                padding-left: 140px;
                padding-right: 100px;
            }
        }

        @include setWidthRwd(1000px,1500px){
            width: 300px;
            padding-left: 1em;
            padding-right: 1em;
            &~.mainContent-list{
                max-width: calc(100% - 300px);
                padding-left: 1em;
                padding-right: 1em;
            }
        }

        @include setMaxRwd{
            .headingLink-title,
            .link-wrap{
                display: none;
            }
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;
        @include setMaxRwd{
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    @include pad1024{
        flex-direction: column;
    }
}


// 內頁左側連結
.link{
    &-wrap {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding:20px 10px 20px 0;
        ul {
            >li {
                >a {
                    position: relative;
                    display: block;
                    line-height: 1.5;
                    color: $colorBase;
                    padding: 0.75em 50px 0.75rem 0.75rem;
    
                    &::after {
                        content: "";
                        font-family: 'bootstrap-icons';
                        display: block;
                        color:#000;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        margin-top: -15px;
                        font-weight:100;
                        @include fontSize(22px);
                    }
    
                    &:hover,
                    &.active {
                        color: $colorMain;
                    }
                }
    
                // 第二層
                &>ol{
                    // display: none;
                    // &.active{
                    //     display: block;
                    // }
                    a{
                        position: relative;
                        display: block;
                        color: rgba(255, 255, 255, 0.75);
                        padding-top: 0.35rem;
                        padding-bottom: 0.35rem;
                        &::before{
                            content: '\f054';
                            font-family: FontAwesome;
                            color: rgba(255, 255, 255, 0.75);
                            @include fontSize(12px);
                            display: block;
                            position: absolute;
                            top: 0.75rem;
                            right: 0.75rem;
                        }
                    }
    
                    li{
                        background-color: $colorMain;
                        border-bottom: #fff 1px dotted;
                        padding-left: 20px;
                        &:hover,
                        &.active{
                            a{
                                &,
                                &::before{
                                    color: rgba(255, 255, 255, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    
        &.pro{
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d9d9d9+100 */
            background: #ffffff; /* Old browsers */
            background: -moz-linear-gradient(left,  #ffffff 0%, #d9d9d9 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #ffffff 0%,#d9d9d9 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #ffffff 0%,#d9d9d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d9d9d9',GradientType=1 ); /* IE6-9 */
            ul {
                >li {
                    >a {
                        @include fontSize(20px);
    
                        &::after {
                            content: "\F4FE";
                        }

                        &:hover,
                        &.active{
                            &::after {
                                content: '\F2EA';
                            }
                        }
                    }
                }
            }
        }

        &.quick,
        &.asideUse{
            background: -moz-linear-gradient(left,  #ffffff 0%, #f4f4f4 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #ffffff 0%,#f4f4f4 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #ffffff 0%,#f4f4f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=1 ); /* IE6-9 */
            margin-top: 2em;
            ul {
                >li {
                    margin-bottom: 0;
                    >a {
                        @include fontSize(16px);
                        color: #747474;
                        display: flex;
                        align-items: center;
                        padding-right: 0;
    
                        &::after {
                            content: "\F280" !important;
                            position: static;
                            margin: 4px 10px 0;
                            @include fontSize(12px);
                            opacity: 0;
                        }

                        &:hover,
                        &.active{
                            color: #000;
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.asideUse{
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d9d9d9+100 */
            background: #ffffff; /* Old browsers */
            background: -moz-linear-gradient(left,  #ffffff 0%, #d9d9d9 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #ffffff 0%,#d9d9d9 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #ffffff 0%,#d9d9d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d9d9d9',GradientType=1 ); /* IE6-9 */
            ul {
                >li {
                    >a {
                        @include fontSize(20px);
                        color: #000;
                    }
                }
            }
        }

        // 第二層使用
        .first_menu{
            padding-left: 1.25rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            margin-top: 0.25rem;
            background: transparent;
            z-index: 1;
            &::after{
                content: '';
                display: block;
                width: 100%;
                min-width: 400px;
                height: 100%;
                background: -moz-linear-gradient(left,  #ffffff 0%, #f4f4f4 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #ffffff 0%,#f4f4f4 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #ffffff 0%,#f4f4f4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=1 ); /* IE6-9 */
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;

                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }

            a{
                color: #7e7e7e !important;
                &.active{
                    color: #000 !important;
                }
            }

            &.open{
                display: block;
            }

            

            @include setMinRwd{
                &::after{
                    left: -25.5%;
                }
            }
        }

        @include setMinRwd{
            padding-left: 80px;

            // 第二層使用
            .first_menu{
                display: none;
            }
        }
    
        @include setWidthRwd(1020px,1500px) {
            padding-left: 1em;
            a {
                @include fontSize(16px);
            }

            // 第二層使用
            .first_menu{
                &::after{
                    min-width: 17em;
                }
            }
        }

        @include setMaxRwd{
            &.quick{
                border-radius: 20px;
                padding: 15px 20px;
                margin-top: 0;
                background: #f4f4f4;
                .link-head {
                    padding-left: 0;
                    margin-bottom: 0.5rem;
                }

                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        flex: 0 0 50%;
                        max-width: 50%;
                        & > a{
                            @include fontSize(15px);
                            line-height: 1.25;
                            padding: 0.5em 0.5em 0.25em 0;
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    
        @include pad1024{
            margin-bottom:1rem;
        }
    }

    &-head{
        @include fontSize(30px);
        color: $colorMain;
        @extend .fw-900;
        padding-left: 0.75rem;
        margin-bottom: 1.5rem;
    }
}

// -------- OTHER -------- //
.unit{
    position: relative;
    display: block;
    line-height: 1.5;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    .btn{
        padding-top: 0.6em;
        padding-bottom: 0.6em;
    }

    &-icon{
        padding-left: 1.5em !important;
        padding-right: 0;
        .icon{
            margin-top: 0;
            position: absolute;
            left: 0;
            top: -0.1rem;
        }
    }

    @include setMaxRwd{
        text-align: left;
        font-size: 16px;
        &-icon{
            .icon{
                top: 0.85rem;
            }
        }
    }
}

.select_style {
    &::after {
        content: '\F282';
        font-family:'bootstrap-icons' !important;
        display: block;
        @include fontSize(14px);
        position: absolute;
        right: 8px;
        top: 1px;
    }

    select.form-control {
        background-color: #fff;
        border: 1px solid #e6eaea !important;
        text-align: left;
        @include radius(0);
        height: 40px !important;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0; 
    }

    select {
        width: 100%;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
    }
    select,
    input {
        border: none !important;
        height: 100% !important;
    }
    .form-control {
        border-radius: 0;
    }
    .disabled {
        background-color: #e9ecef;
        select,
        input {
            background: transparent;
        }
    }
}

// -------- 標題樣式 -------- //
// 內容標題
.title{
    &-wrap{
        text-align: center;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        .btn{
            .icon{
                @include fontSize(23px);
            }
        }

        @include setMaxRwd{
            margin-bottom: 2px;
        }
    }

    // 中英文字
    &-en{
        font-family: $Mitr;
        @include fontSize(35px);
        text-transform: uppercase;
        margin-bottom: 0;
        @extend .fw-900;

        @include pad{
            font-size: get-vw-mobile(40px);
        }
    }

    &-ch{
        position: relative;
        @include fontSize(45px);
        @extend .fw-900;
        color: #595757;

        @include pad{
            font-size: get-vw-mobile(28px);
        }

        @include mobile{
            font-size: get-vw-mobile(48px);
        }
    }

    &-main{
        &Wrap{
            @extend %setFlex-center;
            justify-content: space-between;
            padding-top: 0.25em;
            @include setMaxRwd{
                padding-top: 0.65em;
                padding-bottom: 0.65em;
            }
        }
    }

    &Style{
        position: relative;
        padding: 0.25em;
        &::before {
            content: "";
            display: block;
            @include setSize(8px,8px);
            background-color: $colorItem;
            @include radius(50%);
            position: absolute;
            right: -8px;
            top: 8px;
        }
    }

    // 頂線
    &__topLine{
        border-top-width: 2px;
        border-top-style: solid;
        border-top-color: #000;
    }

    // 底線 : 細
    &__solid{
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #b3b3b3;
    }

    // 底線 : 粗
    &__underLine{
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: #000;
    }

    &-left-circle{
        position: relative;
        padding-left: 30px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            @include setSize(10px,10px);
            border: $colorMain 2px solid;
            border-radius: 50%;
            top: 11px;
            left: 10px;
            z-index: -1;
        }
    }

    &-right-squre{
        position: relative;
        padding-left: 15px;
        z-index: 1;
        &::before {
            content: "";
            @include setSize(15px,15px);
            background-color: #0125fd;
            position: absolute;
            bottom: 2px;
            right: -40px;
            z-index: -1;
        }
    }

    // 左上粗底線
    &__topHead{
        &::before {
            content: '';
            display: block;
            @include setSize(30px,0.5rem);
            background-color: $colorMain;
            margin-bottom: 1rem;
        }
    }
}

// 主標題
.heading-title{
    font-size:get-vw(35px);
    @extend .fw-900;

    @include mobile{
        @include fontSize(25px);
    }

    &-wrap{
        @extend %setFlex-center;
        @include setMinRwd{
            flex-wrap: nowrap
        }
    }
}

// 麵包屑
.breadcrum-wrap {
    margin-top: get-vw(15px);
    margin-bottom: get-vw(15px);

    @include setMaxRwd{
        margin-top: get-vw-mobile(15px);
        margin-bottom: get-vw-mobile(15px);
    }

    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: 0;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        .breadcrumb-item{
            color: #777;
            &+.breadcrumb-item{
                &::before {
                    content: "\F285";
                    font-family: "bootstrap-icons" !important;
                    padding-right: 0.65em;
                }
            }
            
            &.active{
                color: $colorMain;
            }
        }

        @include setMaxRwd{
            padding-left: 0;
            padding-top: 0.3em;
            padding-bottom: 0;
        }
    }

    .icon{
        margin-top: 0px;
        margin-right: 2px;
        img{
            vertical-align: initial
        }
    }
}

// 頁碼
.pagination-wrap {
    @extend %setFlex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(45px,45px);
            @include fontSize(20px);
            line-height: 45px;
            color: #6e6d6d;
            font-family: $Roboto;
            background-color: #fff;
            border-color: #7d7d7d;
            @include radius(0);
            text-align: center;
            padding: 0;
            margin-right: 0.5rem;
            &:hover,
            &.active {
                color: #fff;
                background-color:$colorBlack;
                border-color:$colorBlack;
            }
        }
    }

    .pagination-select{
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        .select{
            &_head{
                flex: none;
                padding-right: 10px;
            }
            &_style{
                width: 100%;
                min-height: auto;
                &::after{
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    @include setMaxRwd{
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}


//-------- 文字 --------//
// 顏色
$textColor-colors: (Main: $colorMain,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: $colorSubText);

.text{
    &-main {
        color: map-get($textColor-colors , Main) !important;
    }

    &-itemSub{
        color: map-get($textColor-colors , itemSub) !important;
    }

    // 樣式
    &-underLine{
        text-decoration: underline !important;
    }

    &-uppercase{
        text-transform: uppercase;
    }

    &-italic{
        font-style: italic;
    }
}

//-------- 區塊色框 --------//
// 顏色
$bgColor-colors: (Main: #064583,Link: #1d2088,Normal: #5d5d5e, Base: $colorBase, itemSub: #6f6e6e);

.bg{
    &-itemSub{
        background-color: map-get($bgColor-colors , itemSub) !important;
    }
}


// 左右對齊
.justify{
    text-align: justify;
}

//-------- 自訂 --------//
.flex{
    &-none{
        flex: none !important;

        @include mobile{
            width: 100%;
        }
    }

    &-auto{
        flex: auto !important
    }

    &-1{
        flex: 1 !important
    }
}


// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    font-size: 14px;
    line-height: 1;
    padding: 6px;
    text-align: center;
    background-color: $bgSecondary;
    color: $colorText; 

    &-current,
    &-date{
        @extend .tag-item;
        @include fontSize(18px);
        color: #fff;
        padding: 8px 16px;
        @include radius(6px);
    }

    &-current{
        background-color: #00b7ee;
    }

    &-date{
        background-color: #bfbf85;
    }

    &-wrap{
        .tag-item{
            & ~ .tag-item{
                margin-left: 5px;
            }
        }

        @include setMinRwd{
            padding-left: 15px;
        }

        @include setMaxRwd{
            padding-top: 10px;
        }
    }
}

// 客製新增的內容
.page-main{
    &Wrapper{
        position: relative;
        padding: 15px;
        &::before{
            content: '';
            display: block;
            @include setSize(100%,100%);
            max-height: get-vw(525px);
            background-color: #f1f0f0;
            position: absolute;
            top: 0;
            left: 0;
            
            @include setWidthRwd(1100px,1400px){
                max-height: get-vw(650px);
            }

            @include setMaxRwd{
                max-height: get-vw-mobile(355px);
            }

            @include mobileSmall{
                max-height: get-vw-mobile(400px);
            }
        }

        @include setMinRwd{
            padding-bottom: 0;
        }

        @include setWidthRwd(1100px,1400px){
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 1rem;
        }
    }

    // 內頁Banner文字區塊
    &Txt-banner{
        padding-top: get-vw(125px);
        padding-bottom: get-vw(85px);

        display: flex;
        flex-direction: column;
        align-items: center;

        &-des,
        &-head{
            display: inline-block;
            font-weight: 100;
        }

        &-des{
            @include fontSize(30px);
            color:#0b3a5b;
            font-family: $Montserrat-thin;
            @include setMaxRwd{
                @include fontSize(25px);
            }
        }

        &-head{
            position: relative;
            display: flex;
            @include fontSize(150px);
            font-family: $Poppins-thin;
            font-style: italic;
            &::after{
                content: '';
                display: block;
                @include setSize(30px,30px);
                background-color: $colorItem;
                @include radius(50%);
                position: relative;
                top: 2.2rem;
                right: -2.2rem;
            }

            @include setMaxRwd{
                @include fontSize(70px);
                &::after{
                    @include setSize(20px,20px);
                    top: 3.2rem;
                    right: -1rem;
                }
            }
        }

        &-arrowBottom{
            color: #595959;
            padding-top: get-vw(25px);
            padding-bottom: get-vw(25px);
        }

        @include setMaxRwd{
            padding-top: get-vw(75px);
        }
    }
}

.detail_label {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    padding: 0 6px;
    height: 20px;
    text-align: center;
    background-color: #fff;
    line-height: 20px;
    vertical-align: middle;
    font: 13px/25px Arial;
    border: 1px solid #fff;
    &_other {
        border-color: #e9a3a8;
        color: #e9a3a8;
        line-height: 20px;
    }
}