.news{
    // 列表
    &-card{
        &-img-top{
            border: #d9d9d9 2px solid;
            margin-bottom: -2px !important;
            img{
                width: 100%;
            }
        }

        &-title{
            @include fontSize(20px);
            font-family: "century-gothic", $fontNotosanc-tc;
            line-height: 1.25;
            margin-bottom: 1rem;
            font-weight: 600;
            @include setMinRwd{
                @include fontSize(30px);
                min-height: 4.75rem;
            }
        }

        &-des{
            @include fontSize(16px);
            font-family: $Poppins, $fontNotosanc-tc;
            color: $colorText;
            @include setMinRwd{
                padding-top: get-vw(30px);
                padding-bottom: get-vw(75px);
                line-height: 1.5;
            }
        }
        
        &-time{
            font-family: $Roboto;
            margin-top: auto;
            margin-bottom: 0.25em;
            padding-top: 1em;
        }
    }

    &-card-wrap{
        .card{
            &-body,
            &-btn{
                width: 100%;
                border:#d9d9d9 2px solid;
                padding: 1.25em 1.5em;
                @include setMaxRwd{
                    padding-left: 0.75em;
                    padding-right: 0.75em;
                }
            }

            &-body{
                @include setMinRwd{
                    padding-top: 1.5em;
                    padding-bottom: 0.5em;
                }

                @include setMaxRwd{
                    padding-top: 0.5em;
                    padding-bottom: 0.25em;
                }

                display: flex;
                flex-direction: column;
                .card-time{
                    margin-top: auto;
                }
            }

            &-btn{
                background-color: transparent !important;
                color: #000 !important;
                justify-content: flex-start;
                margin-top: -2px;
                margin-bottom: 0;
                @include setMaxRwd{
                    padding-top: 0.5em;
                    padding-bottom: 0.7em;
                }
            }

            @include setMinRwd{
                margin-left: get-vw(40px);
                margin-right: get-vw(40px);
                flex: 0 0 calc((100% / 3) - (get-vw(40px) * 2));
                max-width: calc((100% / 3) - (get-vw(40px) * 2));
            }
        }
    }

    // 詳細頁
    &-content{
        .news-card-time{
            border-top: 2px solid #d9d9d9;
            border-bottom: 2px solid #d9d9d9;
            padding-top: 0.75rem;
            padding-bottom: 0.5rem;
        }

        // 
        &-head{
            margin-bottom: 1rem;
            @include setMinRwd{
                float: left;
                width: calc(450 / 1350 * 100%);
                min-height: get-vw(570px);
                padding-right: get-vw(120px);
                .news-card-title{
                    @include setMinRwd{
                        margin-bottom: get-vw(50px);
                    }
                }
            }

            @include setWidthRwd(1100px,1500px){
                min-height: get-vw(700px);
            }
        }

        &-article{
            font-family: $Poppins, $fontNotosanc-tc;
            line-height: 1.75;
        }
    }
}